exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-url-js": () => import("./../../../src/pages/app-url/[...].js" /* webpackChunkName: "component---src-pages-app-url-js" */),
  "component---src-templates-blog-category-pagination-js": () => import("./../../../src/templates/blog-category-pagination.js" /* webpackChunkName: "component---src-templates-blog-category-pagination-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-plugin-js": () => import("./../../../src/templates/plugin.js" /* webpackChunkName: "component---src-templates-plugin-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

