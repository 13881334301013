import React from 'react';

import { Button } from 'soluble-design-system-base';

import style from './style.module.scss';

function Arrow() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22 12C22 17.5226 17.5226 22 12 22C6.4774 22 2 17.5226 2 12C2 6.4774 6.4774 2 12 2C17.5226 2 22 6.4774 22 12ZM11.7762 7.6079C11.4749 7.90707 11.4731 8.3938 11.7721 8.69523L14.2875 11.2308H7.86539C7.44055 11.2308 7.09615 11.5752 7.09615 12C7.09615 12.4248 7.44055 12.7692 7.86539 12.7692H14.2875L11.7721 15.3048C11.4731 15.6062 11.4749 16.0929 11.7762 16.3921C12.0777 16.6914 12.5647 16.6896 12.864 16.3881L16.5193 12.7044C16.9062 12.3145 16.9062 11.6855 16.5193 11.2956L12.864 7.61193C12.5647 7.31038 12.0777 7.30857 11.7762 7.6079Z"
                fill="#231F20"
            />
        </svg>
    );
}

export default function ButtonComponent({
                                            button = null,
                                            buttons = [],
                                            buttonClassName,
                                            blockMobile,
                                            dark,
                                            night,
                                            hideArrow = false,
                                            defaultTag,
                                        }) {
    let theButtons = button ? [button] : buttons;

    if (typeof window === 'undefined') {
        return <></>;
    }

    theButtons = theButtons.map(button => {
        if (button.externalLink === "#b2b_login") {
            button.externalLink = `${process.env.GATSBY_B2B_URL_LOGIN}`;
        }
        if (button.externalLink === "#b2c_login") {
            button.externalLink = `${process.env.GATSBY_B2C_URL_LOGIN}`;
        }
        if (button.externalLink === "#external_signup") {
            button.externalLink = `${process.env.GATSBY_EXTERNAL_URL_SIGNUP}?source=${window.location.pathname}`;
        }
        return button;
    });

    return (
        <>
            {theButtons.map((button, i) => (
                <Button
                    key={i}
                    className={[
                        style.button,
                        style[
                            `button--${button.detached ? 'primary' : 'secondary'
                            }`
                            ],
                        button.disabled ? style['button--disabled'] : '',
                        button.black ? style['button--black'] : '',
                        button.active ? style['button--active'] : '',
                        button.hover ? style['button--hover'] : '',
                        dark ? style['button--dark'] : '',
                        night ? style['button--night'] : '',
                        buttonClassName ? buttonClassName : '',
                        typeof button.label === 'string' &&
                        button.label.length > 20
                            ? style['button--multiline']
                            : '',
                        blockMobile ? style['button--xs-block'] : '',
                    ].join(' ')}
                    block={button.block ? true : false}
                    type={button.detached ? 'primary' : 'link'}
                    isGatsby={button.link && button.link.slug ? true : false}
                    Tag={
                        button.Tag
                            ? button.Tag
                            : button.link && button.link.slug && defaultTag
                                ? defaultTag
                                : 'a'
                    }
                    href={
                        button.link && button.link.slug
                            ? `/${button.link.slug}/`
                            : button.externalLink
                                ? button.externalLink
                                : '#'
                    }
                    target={
                        button.target
                            ? button.target
                            : (!button.link || !button.link.slug) &&
                            button.externalLink &&
                            button.openInNewTab
                                ? '_blank'
                                : '_self'
                    }
                    isSubmit={button.isSubmit ? true : false}
                    onClick={
                        button.onClick
                            ? button.onClick
                            : () => {
                                let data = window['dataLayer'];
                                if (data) {
                                    if (button.event) {
                                        data.push({ event: button.event });
                                        return;
                                    }
                                    data.push({ event: `button-click` });
                                }
                            }
                    }
                    disabled={button.disabled ? true : false}
                    iconClass={button.iconClass != '' ? button.iconClass : null}
                    extraAttributes={
                        button.extraAttributes ? button.extraAttributes : {}
                    }>
                    {!button.detached && !hideArrow ? (
                        <>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: button.label,
                                }}
                            />
                            <Arrow />
                        </>
                    ) : (
                        button.label
                    )}
                </Button>
            ))}
        </>
    );
}
