import React from 'react';

import { className, Spinner } from 'aplazame-design-system';
import { default as ButtonComponent } from 'aplazame-design-system/src/components/GatsbyTransitionButtonComponent';
import FormAlert from '../FormAlert';

import style from './style.module.scss';


export default function FormSubmit({
    text = '',
    loading = false,
    allowSubmit = true,
    onClick,
    isSubmit = true,
    error = null,
    blockMobile = false
}) {
    return (
        <div {...className(`w-100 ${error ? '' : style['without-error']}`)}>
            {error ? (
                <FormAlert>{error}</FormAlert>
            ) : null}
            <ButtonComponent
                blockMobile={blockMobile}
                button={{
                    detached: true,
                    block: blockMobile ? false : true,
                    Tag: 'button',
                    isSubmit,
                    onClick,
                    disabled: loading || !allowSubmit,
                    label: loading ? <Spinner forButton={true} /> : text,
                    active: loading
                }}
            />
        </div>
    );
}