import React, { useRef, useEffect, useState, useCallback } from 'react';
import { navigate } from 'gatsby';

import { vendorsActivate } from '../../api';
import { DASHBOARD_ROUTES } from '../../api/routes';

import { useTranslate } from 'soluble-gatsby-source';
import { ModalBase } from 'aplazame-design-system';
import FormSubmit from '../FormSubmit';

import style from './style.module.scss';


export default function ModalSignupActivation({
    uuid,
    token
}) {
    const translate = useTranslate();
    const modalRef = useRef();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const onToggle = useCallback(
        show => {
            if (!show) {
                navigate('/');
            }
        },
        []
    );

    useEffect(() => {
        if (modalRef.current) {
            modalRef.current.toggle();
        }
    }, [modalRef]);

    useEffect(() => {
        vendorsActivate(uuid, token)
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [setError, setLoading, uuid, token])

    const onClick = useCallback(
        () => {
            if (loading) {
                return;
            }

            if (error) {
                modalRef.current.toggle();
                return;
            }

            window.location.href = DASHBOARD_ROUTES.vendors;
        },
        [error, loading]
    );

    return (
        <ModalBase
            parentRef={modalRef}
            onToggle={onToggle}
            success={!loading && !error}
            error={error}
            iconTimeout={0}
            title={loading ?
                translate['modal.signup-activation.b2b.title'].text :
                (error ?
                    translate['modal.signup-activation.b2b.error.message'].text :
                    translate['modal.signup-active.b2b.title'].text
                )
            }
            size="md"
            mainClassName={style.wrapper}
            headerClassName={style['wrapper__header']}
            hideClose={true}
            disableBackgroundClose={true}
            footer={(
                <FormSubmit
                    text={error ?
                        translate['modal.signup-activation.b2b.error.submit'].text :
                        translate['modal.signup-active.b2b.submit'].text
                    }
                    loading={loading}
                    allowSubmit={!loading}
                    onClick={onClick}
                />
            )}
        />
    );
}