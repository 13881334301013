import React from 'react';

import AniLink from 'gatsby-plugin-transition-link/AniLink';


export default function TransitionLink({
    to,
    activeClassName,
    children,
    partiallyActive,
    ...props
}) {
    if (!to.indexOf('http') || !to.indexOf('mailto:') || to.startsWith('#')) {
        return (
            <a 
                href={to}
                {...props}
                {...props.target && props.target === '_blank' && { rel: 'noopener nofollow' }}
            >
                {children}
            </a>
        );
    }

    let cleanTo = to.endsWith('/') || to.indexOf('#') !== -1 ? to : `${to}/`;
    if (!cleanTo.startsWith('/')) {
        cleanTo = `/${cleanTo}`;
    }
    
    return (
        <AniLink
            to={cleanTo}
            cover
            direction="right"
            duration={2}
            bg="#f0f2ff"
            partiallyActive={partiallyActive ? partiallyActive : false}
            activeClassName={activeClassName ? activeClassName : null}
            {...props}
        >
            {children}
        </AniLink>
    );
}