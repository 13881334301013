import React, {
    useRef,
    useEffect,
    useState,
    forwardRef,
    useCallback,
    createElement
} from 'react';
import ReactDOM from 'react-dom';
import { useStaticQuery, graphql } from 'gatsby';

import serialize from 'form-serialize';

import { vendorsSignup } from '../../api';

import { useTranslate } from 'soluble-gatsby-source';

import FormSignupVendorsHubspotPassword from '../FormSignupVendorsHubspotPassword';
import HubspotForm from '../HubspotForm';
import FormSpinner from '../FormSpinner';
import FormAlert from '../FormAlert';

import style from './style.module.scss';


export default forwardRef(function FormSignupVendorsHubspot(
    { onSuccess, onError },
    ref
) {
    const data = useStaticQuery(graphql`
        query  {
            blacklist: datoCmsSetting {
                signupB2bBlacklistEmailDomains
            }
        }
    `);

    const translate = useTranslate();

    const [loading, setLoading] = useState(false);
    const [allowSubmit, setAllowSubmit] = useState(false);

    const passwordRef = useRef();

    const checkBlacklistEmail = useCallback(
        (email) => {
            if (!email || !data.blacklist || !data.blacklist.signupB2bBlacklistEmailDomains || !data.blacklist.signupB2bBlacklistEmailDomains.length) {
                return false;
            }
            
            return data.blacklist.signupB2bBlacklistEmailDomains.find(domain => email.indexOf(`@${domain}`) !== -1) ? true : false;
        },
        [ data ]
    )

    const onThisToggleAllowSubmit = useCallback(
        allowSubmit => {
            setAllowSubmit(allowSubmit);
        },
        [ setAllowSubmit ]
    );
    
    const formatHubspotForm = useCallback(
        (formRef) => {
            if (typeof window === 'undefined') {
                return;
            }

            function changeApzValidatedValue(checked) {
                let apzValidatedInput = formRef.querySelector('input[name="apz_validated"]');
                if (apzValidatedInput && apzValidatedInput.checked !== checked) {
                    apzValidatedInput.parentNode.click();
                    setTimeout(() => {
                        changeApzValidatedValue(checked);
                    }, 500);
                }
            }
            
            changeApzValidatedValue(false);

            let div = window.document.createElement('div');
            const legalItem = formRef.querySelector('.legal-consent-container');
            const legalItemFieldset = legalItem.parentNode;
            legalItemFieldset.parentNode.insertBefore(div, legalItemFieldset);

            let errorDiv = window.document.createElement('div');
            errorDiv.id = 'hubspot-custom-error-form';
            const submitWrapper = formRef.querySelector('.hs_submit');
            submitWrapper.parentNode.insertBefore(errorDiv, submitWrapper);
    
            ReactDOM.render(
                createElement(
                    FormSignupVendorsHubspotPassword,
                    {
                        translate,
                        onToggleAllowSubmit: onThisToggleAllowSubmit,
                        ref: passwordRef
                    }
                ),
                div
            );

            let form = formRef.querySelector('form');

            function customErrorOnHubspotInput(domSelector, errorMessage) {
                const input = formRef.querySelector(domSelector);
                if (!input || input.dataset.error === 'true') {
                    return;
                }

                input.dataset.error = 'true';
                let errorField = window.document.createElement('ul');
                errorField.role = 'alert';
                errorField.classList.add('no-list');
                errorField.dataset.errorMessage = 'true';
                errorField.innerHTML = `
                    <li>
                        <label class="hs-error-msg">
                            ${errorMessage}
                        </label>
                    </li>
                `;
                input.parentNode.after(errorField);
            }

            function getFormData() {
                let data = serialize(form, { hash: true });
                
                const emailIsBlacklist = checkBlacklistEmail();
                const merchantUrlisValidUrl = /^(www|http)/.test(data.website);
                const passwordIsValid = passwordRef.current.validate();

                let hasHubspotErrors = false;
                [ ...formRef.querySelectorAll('.hubspot-field-with-error') ].forEach(node => {
                    let fieldWithErrorClassname = node.parentNode.parentNode.className;
                    if (fieldWithErrorClassname.indexOf('hs-apz_validated') === -1) {
                        hasHubspotErrors = true;
                    }
                });
                [ ...formRef.querySelectorAll('.hs-error-msg') ].forEach(node => {
                    let fieldWithErrorClassname = node.parentNode.parentNode.parentNode.className;
                    if (fieldWithErrorClassname.indexOf('hs-apz_validated') === -1) {
                        hasHubspotErrors = true;
                    }
                });

                if (
                    !data.profile ||
                    !data.firstname ||
                    !data.lastname ||
                    !data.email ||
                    emailIsBlacklist ||
                    !data.website ||
                    !merchantUrlisValidUrl ||
                    !data.tamano_cesta_media__c ||
                    !data.numero_de_pedidos_al_mes_c__c ||
                    !passwordIsValid ||
                    !data['LEGAL_CONSENT.processing'] ||
                    hasHubspotErrors
                ) {
                    if (data.website && !merchantUrlisValidUrl) {
                        customErrorOnHubspotInput('input[name="website"]', translate['modal.signup.b2b.merchant_url.error'].text);
                    }

                    if (data.email && emailIsBlacklist) {
                        customErrorOnHubspotInput('input[name="email"]', translate['modal.signup.b2b.email.error.blacklist'].text);
                    }
                    
                    ReactDOM.render(
                        createElement(
                            FormAlert,
                            { children: translate['modal.signup.b2b.error'].text }
                        ),
                        errorDiv
                    );
                    
                    return false;
                }

                return data;
            }

            function performApzSignup(data, successCallback, errorCallback) {
                vendorsSignup({
                    type: data.profile.indexOf('employed') !== -1 ? 'self_employed' : 'enterprise',
                    first_name: data.firstname,
                    last_name: data.lastname,
                    email: data.email,
                    profile: {
                        merchant_url: data.website,
                        avg_cart_size: data.tamano_cesta_media__c,
                        monthly_orders: data.numero_de_pedidos_al_mes_c__c,
                        additional_fields: {
                            aplazame_conditions: data['LEGAL_CONSENT.processing'] == 'true' ? true : false
                        },
                    },
                    password: data.password,
                    password2: data.password2,
                })
                .then(response => {
                    function onFormSubmitted(event) {
                        if (event.data.formTarget && event.data.conversionId) {
                            let hubspotResponse = formRef.querySelector('.submitted-message');

                            onSuccess(
                                hubspotResponse ? hubspotResponse.innerHTML : translate['modal.signup.b2b.success.text'].md.replace('{email}', data.email),
                                response && response.url ? response.url : null
                            );

                            if (successCallback) {
                                successCallback();
                            }

                            window.removeEventListener('message', onFormSubmitted);
                        }
                    }
                    
                    window.addEventListener('message', onFormSubmitted);

                    changeApzValidatedValue(true);

                    var form = formRef.querySelector('form');
                    form.querySelectorAll(`[type="password"]`).forEach(e => e.remove());
                    form.submit();
                })
                .catch(response => {
                    if (response) {
                        if (response.error && response.error.fields) { 
                            if(response.error.fields.email && response.error.fields.email.length) {
                                customErrorOnHubspotInput('input[name="email"]', response.error.fields.email[0]);
                            }
        
                            if (response.error.fields.profile && response.error.fields.profile.merchant_url && response.error.fields.profile.merchant_url.length) {
                                customErrorOnHubspotInput('input[name="website"]', response.error.fields.profile.merchant_url[0]);
                            }
                        }
                        
                        const loginError = response.non_field_errors || !Object.keys(response).length;
                        if (loginError === 'service_unavailable') {
                            setLoading(false);
                            onError(loginError)
                        }
                    }

                    ReactDOM.render(
                        createElement(
                            FormAlert,
                            {
                                children: translate[response && response.error && response.error.fields ? 'modal.signup.b2b.error' : 'modal.signup.b2b.submit.error'].text
                            }
                        ),
                        errorDiv
                    );

                    if (errorCallback) {
                        errorCallback();
                    }
                });
            }
            
            function formEventListener() {
                let data = getFormData();
                if (!data) {
                    return;
                }

                setLoading(true);

                // Wait for Hubspot to process fields
                setTimeout(
                    () => {
                        data = getFormData();
                        if (!data) {
                            setLoading(false);
                            return;
                        }

                        performApzSignup(
                            data,
                            () => {
                                setLoading(false);
                            },
                            () => {
                                setLoading(false);
                            }
                        );
                    }, 1500
                );
            };

            form.addEventListener('submit', formEventListener); 
        },
        [ translate, onThisToggleAllowSubmit, setLoading, checkBlacklistEmail, passwordRef, onSuccess ]
    );

    const onFocusField = useCallback(
        () => {
            let errorDiv = window.document.querySelector('#hubspot-custom-error-form');
            if (errorDiv) {
                ReactDOM.unmountComponentAtNode(errorDiv);
            }
        },
        []
    );
    
    return (
        <div className={style.wrapper}>
            <HubspotForm
                portalId={process.env.GATSBY_VENDOR_SIGNUP_FORM_PORTAL_ID}
                formId={process.env.GATSBY_VENDOR_SIGNUP_FORM_FORM_ID}
                forceLoadingStatus={loading}
                onReady={formatHubspotForm}
                submitDisabled={!allowSubmit}
                submitBlock={true}
                blockedDomains={data && data.blacklist && data.blacklist.signupB2bBlacklistEmailDomains ? data.blacklist.signupB2bBlacklistEmailDomains : []}
                className={style.form}
                onFocusField={onFocusField}
                loading={
                    <FormSpinner />
                }
            />
        </div>
    );
});