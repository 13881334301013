import React, { forwardRef } from 'react';

import { useTranslate } from 'soluble-gatsby-source';
import { FormInput as ADSFormInput } from 'aplazame-design-system';


export default forwardRef(function FormInput(props, ref) {
    const translate = props.translate ? props.translate : useTranslate();

    return (
        <ADSFormInput 
            ref={ref}
            {...props}
            defaultRequiredMessage={translate['form.required.error'].text}
            defaultEmailMessage={translate['form.email.error'].text}
        />
    )
})