import React, {
    useEffect,
    useState,
    useImperativeHandle,
    useRef,
    forwardRef,
    useCallback,
} from 'react';

import { FormGroup } from 'soluble-design-system-base';

import style from './style.module.scss';

import transitionStyle from '../../scss/transitions.module.scss';

export default forwardRef(function FormInput(
    {
        onFocus,
        onToggleActive,
        className,
        onChange,
        onSubmit,
        defaultRequiredMessage = 'Field required',
        defaultEmailMessage = 'Valid email required',
        extraAtts = {},
        disabled,
        onBlur,
        labelClassName,
        ...props
    },
    ref,
) {
    const inputRef = useRef();

    const [isActive, setIsActive] = useState(props.value ? true : false);
    const [isSelected, setIsSelected] = useState(props.value ? true : false);

    useEffect(() => {
        if (onToggleActive) {
            onToggleActive(isActive);
        }
    }, [isActive, onToggleActive]);

    useEffect(() => {
        setIsActive(props.value ? true : false);
        if (inputRef && props.value) inputRef.current.setValue(props.value);
    }, [props.value]);

    const handleFocus = useCallback(
        (e) => {
            setIsActive(true);
            if (onFocus) {
                onFocus(e);
            }
        },
        [onFocus],
    );

    const handleBlur = useCallback((e) => {
        let value = inputRef.current.getValue();

        if (typeof value === 'string' && !value.trim()) {
            setIsActive(false);
        }
        if (onBlur) {
            onBlur();
        }
    }, []);

    const handleSelectChange = useCallback(
        (e) => {
            setIsSelected(e.target.selectedIndex ? true : false);
            if (onChange) {
                onChange(e);
            }
        },
        [setIsSelected, onChange],
    );

    useImperativeHandle(ref, () => ({
        setError: (error) => inputRef.current.setError(error),
        setValue: (value) => {
            inputRef.current.setValue(value);
            if (props.type === 'select') {
                setIsSelected(value ? true : false);
                setIsActive(value ? true : false);
            } else if (!value && !inputRef.current.isFocused()) {
                setIsActive(false);
            }
        },
        getValue: () => inputRef.current.getValue(),
        validate: () => inputRef.current.validate(),
        clearError: () => inputRef.current.clearError(),
    }));

    return (
        <FormGroup
            ref={inputRef}
            defaultMessages={{
                required: defaultRequiredMessage,
                email: defaultEmailMessage,
            }}
            className={[
                style.wrapper,
                props.type === 'checkbox' || props.type === 'radio'
                    ? style['wrapper--checkbox']
                    : style['wrapper--input'],
                isActive ? style['wrapper--active'] : '',
                props.type === 'checkbox'
                    ? transitionStyle['child-link-underline']
                    : '',
                className ? className : '',
            ].join(' ')}
            labelClassName={`${style['wrapper__label']} ${props.hero ? style['hero--label'] : ''} ${labelClassName ? labelClassName : ''
                }`}
            validatorClassName={style['wrapper__feedback']}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={
                props.type === 'select'
                    ? handleSelectChange
                    : onChange
                        ? onChange
                        : null
            }
            extraAtts={{
                ...extraAtts,
                ...(isSelected ? { 'data-selected': true } : {}),
            }}
            disabled={disabled}
            {...props}
        />
    );
});
