import React, {
    useRef,
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle,
    useCallback,
} from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { vendorsLogin } from '../../api';

import { className, Text } from 'aplazame-design-system';
import { useTranslate } from 'soluble-gatsby-source';
import FormInput from '../FormInput';

import transitions from 'aplazame-design-system/src/scss/transitions.module.scss';

export default forwardRef(function FormLoginVendors(
    { onToggleLoading, onError, showMaintenanceModal },
    ref
) {
    const translate = useTranslate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const identificationRef = useRef();
    const passwordRef = useRef();

    const data = useStaticQuery(graphql`
        query {
            settings: datoCmsSetting {
                loginB2bRememberPasswordHash
            }
        }
    `);

    useEffect(() => {
        if (onToggleLoading) {
            onToggleLoading(loading);
        }
    }, [loading, onToggleLoading]);

    useEffect(() => {
        if (!error) {
            passwordRef.current.clearError();
        }

        if (onError) {
            onError(error);
        }
    }, [error, onError]);

    const submitForm = useCallback(
        (e) => {
            if (e) {
                e.preventDefault();
            }
            const idIsValid = identificationRef.current.validate();
            const passwordIsValid = passwordRef.current.validate();
            if (!idIsValid || !passwordIsValid) {
                return;
            }
            setLoading(true);
            vendorsLogin(
                identificationRef.current.getValue(),
                passwordRef.current.getValue()
            )
                .catch((error) => {
                    const loginError =
                        error.non_field_errors || !Object.keys(error).length;
                    if (loginError === 'service_unavailable') {
                        showMaintenanceModal(true);
                        return;
                    }
                    setError(translate['modal.login.b2b.error'].text);
                    passwordRef.current.setError(' ');
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [translate]
    );

    const reset = useCallback(() => {
        setError(null);
        identificationRef.current.clearError();
        identificationRef.current.setValue('');
        passwordRef.current.clearError();
        passwordRef.current.setValue('');
    }, []);

    const onFocus = useCallback(() => {
        setError(null);
    }, []);

    useImperativeHandle(ref, () => ({
        submitForm,
        reset,
    }));

    return (
        <form onSubmit={submitForm}>
            <Text tag="div" className="body pt-5 pb-3">
                {translate['modal.login.b2c.text'].md}
            </Text>
            <FormInput
                ref={identificationRef}
                type="email"
                name="identification"
                required={true}
                label={translate['modal.login.b2b.form.email'].text}
                onFocus={onFocus}
            />
            <FormInput
                ref={passwordRef}
                type="password"
                name="password"
                required={true}
                label={translate['modal.login.b2b.form.password'].text}
                onFocus={onFocus}
            />
            <a
                href={`#${data.settings.loginB2bRememberPasswordHash}`}
                {...className(
                    `body mb-2 mb-lg-0 d-block ${transitions['link-underline']}`
                )}
            >
                {translate['modal.login.b2b.remember'].text}
            </a>

            <button type="submit" {...className('d-none')} />
        </form>
    );
});
