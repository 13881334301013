import React, { useEffect } from 'react';
import sal from 'sal.js';
import 'sal.js/dist/sal.css';

const SalWrapper = ({
    children
}) => {
    useEffect(() => {
        sal({
            threshold: 0.2,
            once: true,
            rootMargin: '0% 100%'
        });
    }, []);

    return <>{children}</>;
};

export { SalWrapper };