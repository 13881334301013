import React, { useRef, useEffect, useState } from 'react';

import {
    Heading,
    Container,
    Row,
    Col,
    Text,
    className,
} from 'soluble-design-system-base';

import { ButtonComponent } from 'aplazame-design-system';
import ImageComponent from '../ImageComponent';

import style from './style.module.scss';
import textStyle from '../../scss/text.module.scss';
import sectionStyle from '../../scss/section.module.scss';
import colorStyle from '../../scss/color.module.scss';
import VideoComponent from '../VideoComponent';

export default function FeatureSection({
    previousSectionType,
    nextSectionType,
    titleTag,
    titleNode,
    titleDetached,
    textSmall,
    text,
    imagePositionDesktop,
    imagePositionMobile,
    image,
    image2,
    video,
    buttons,
    colorScheme,
    backgroundColorScheme,
    showVipAppLeadListForm,
    FormVipAppLeadList,
    ThisButtonComponent = ButtonComponent,
    ...props
}) {
    backgroundColorScheme = backgroundColorScheme.toLowerCase();
    colorScheme = colorScheme?.toLowerCase();
    const imageMobileIsBottom = imagePositionMobile === 'Bottom';
    const imageDesktopIsRight = imagePositionDesktop === 'Right';
    const hasFeatureSectionAround = [
        previousSectionType,
        nextSectionType,
    ].includes('DatoCmsFeatureSection');
    const hasBackgroundColorScheme = backgroundColorScheme !== 'none';
    const hasHeroBefore = ['DatoCmsHeroSection'].includes(previousSectionType);
    const isBigCta = titleDetached && buttons && buttons.length;
    const [playVideo, setPlayVideo] = useState(false);
    const videoWrapperRef = useRef();

    useEffect(() => {
        if (
            typeof window !== 'undefined' &&
            video &&
            videoWrapperRef?.current
        ) {
            const onEndAnimation = () => {
                setPlayVideo(true);
            };

            window.addEventListener('sal:in', onEndAnimation);
            return () => {
                window.removeEventListener('sal:in', onEndAnimation);
            };
        }
    }, [videoWrapperRef, video, setPlayVideo]);

    let cmp = (
        <>
            <Col
                col={{ lg: 6 }}
                offset={{
                    lg: isBigCta && !imageDesktopIsRight ? 1 : 0,
                    xl:
                        hasBackgroundColorScheme && !imageDesktopIsRight
                            ? 1
                            : 0,
                }}
                order={{
                    xs: imageMobileIsBottom ? 1 : 2,
                    lg: imageDesktopIsRight ? 1 : 2,
                }}
                pt={{
                    xs: imageMobileIsBottom
                        ? 0
                        : hasFeatureSectionAround ||
                            hasBackgroundColorScheme ||
                            hasHeroBefore ||
                            isBigCta
                            ? 5
                            : 10,
                    lg: 0,
                }}>
                <Heading
                    tag={titleTag}
                    className={[
                        titleDetached ? style.headingBig : 'h2',
                        text ? '' : 'mb-xxl-10',
                        titleDetached || !titleNode.hasUnderline
                            ? textStyle.gradual
                            : '',
                        textStyle['child-underline'],
                        textStyle[`child-underline--${colorScheme}`],
                        style.heading,
                        props.rectangularImage
                            ? style.heading4ShapeRectangle
                            : '',
                    ].join(' ')}
                    data-sal={`slide-up--lg-${imageDesktopIsRight ? 'right' : 'left'
                        }`}
                    data-sal-easing="ease-out-quart">
                    {titleNode.contentWithoutWrapperUnderline}
                </Heading>
                {text ? (
                    <Text
                        tag="div"
                        className={`${textSmall ? 'h5' : 'h3'} ${style.text} ${textSmall ? style.textSmall : ''
                            } font-weight-light mt-${imageMobileIsBottom && !titleDetached ? 3 : 5
                            } mt-lg-5 ${props.rectangularImage
                                ? style.text4ShapeRectangle
                                : ''
                            }`}
                        data-sal={`slide-up--lg-${imageDesktopIsRight ? 'right' : 'left'
                            }`}
                        data-sal-easing="ease-out-quart">
                        {text}
                    </Text>
                ) : null}
                {buttons && buttons.length ? (
                    <div
                        {...className('text-center text-lg-left mt-5')}
                        data-sal={`slide-up--lg-${imageDesktopIsRight ? 'right' : 'left'
                            }`}
                        data-sal-easing="ease-out-quart">
                        <ThisButtonComponent
                            blockMobile={isBigCta}
                            buttons={buttons}
                        />
                    </div>
                ) : null}
                {showVipAppLeadListForm ? (
                    <div
                        data-sal={`slide-up--lg-${imageDesktopIsRight ? 'right' : 'left'
                            }`}
                        data-sal-easing="ease-out-quart">
                        <FormVipAppLeadList />
                    </div>
                ) : null}
            </Col>
            <Col
                offset={{
                    lg: isBigCta && imageDesktopIsRight ? 1 : 0,
                    xl:
                        (hasBackgroundColorScheme || isBigCta) &&
                            imageDesktopIsRight
                            ? 1
                            : 0,
                }}
                col={{
                    lg: isBigCta ? 5 : 6,
                    xl: hasBackgroundColorScheme || isBigCta ? 5 : 6,
                }}
                order={{
                    xs: imageMobileIsBottom ? 2 : 1,
                    lg: imageDesktopIsRight ? 2 : 1,
                }}
                pt={{
                    xs: imageMobileIsBottom
                        ? titleDetached || text
                            ? 5
                            : 3
                        : 0,
                    lg: 0,
                }}>
                <div
                    ref={videoWrapperRef}
                    data-sal={`slide-up--lg-${imageDesktopIsRight ? 'left' : 'right'
                        }`}>
                    {image ? (
                        <ImageComponent
                            className={`${style.image} ${props.rectangularImage
                                ? style.imageShapeRectangular
                                : ''
                                } ${imagePositionDesktop === 'Left'
                                    ? style.left
                                    : ''
                                }`}
                            image={image}
                        />
                    ) : video ? (
                        <VideoComponent {...video} play={playVideo} />
                    ) : null}
                </div>
                {image2 ? (
                    <div
                        data-sal={`slide-up--lg-${imageDesktopIsRight ? 'left' : 'right'
                            }`}>
                        <ImageComponent image={image2} className="mt-3" />
                    </div>
                ) : null}
            </Col>
        </>
    );

    if (props.style === 'Video bottom') {
        cmp = (
            <div className={style.wrapperB}>
                <div>
                    <Heading
                        tag={titleTag}
                        className={[
                            titleDetached ? style.headingBig : 'h2',
                            text ? '' : 'mb-xxl-10',
                            titleDetached || !titleNode.hasUnderline
                                ? textStyle.gradual
                                : '',
                            textStyle['child-underline'],
                            textStyle[`child-underline--${colorScheme}`],
                            style.heading,
                        ].join(' ')}
                        data-sal="slide-up"
                        data-sal-easing="ease-out-quart">
                        {titleNode.contentWithoutWrapperUnderline}
                    </Heading>
                    {text ? (
                        <Text
                            tag="div"
                            className={`${textSmall ? 'h5' : 'h3'} ${style.text
                                } font-weight-light mt-${imageMobileIsBottom && !titleDetached ? 3 : 5
                                } mt-lg-5`}
                            data-sal="slide-up"
                            data-sal-easing="ease-out-quart">
                            {text}
                        </Text>
                    ) : null}
                    {buttons && buttons.length ? (
                        <div
                            {...className('text-center text-lg-left mt-5')}
                            data-sal="slide-up"
                            data-sal-easing="ease-out-quart">
                            <ThisButtonComponent
                                blockMobile={isBigCta}
                                buttons={buttons}
                            />
                        </div>
                    ) : null}
                    {showVipAppLeadListForm ? (
                        <div
                            data-sal="slide-up"
                            data-sal-easing="ease-out-quart">
                            <FormVipAppLeadList />
                        </div>
                    ) : null}
                </div>
                <div
                    className={style.videoStyleB}
                    ref={videoWrapperRef}
                    data-sal="slide-up"
                    data-sal-easing="ease-out-quart">
                    {image ? (
                        <ImageComponent className={style.image} image={image} />
                    ) : video ? (
                        <VideoComponent {...video} play={playVideo} />
                    ) : null}
                </div>
                {image2 ? (
                    <div data-sal="slide-up" data-sal-easing="ease-out-quart">
                        <ImageComponent image={image2} className="mt-3" />
                    </div>
                ) : null}
            </div>
        );
    }

    return (
        <section
            {...className(
                [
                    sectionStyle['with-bg'],
                    sectionStyle['with-bg--none'],
                    colorStyle[`with-bg--${backgroundColorScheme}`],
                    hasBackgroundColorScheme
                        ? `mt-10 mb-10 mt-lg-${hasHeroBefore ? 30 : 10
                        } mb-lg-10`
                        : '',
                    nextSectionType === 'DatoCmsBlockTextWithImageSectionV2'
                        ? style['mb-0']
                        : '',
                ].join(' '),
            )}>
            {hasBackgroundColorScheme ? (
                <div
                    data-sal="fade"
                    data-sal-easing="ease-out-quart"
                    className={`${sectionStyle.bg} ${colorStyle.bg}`}
                />
            ) : null}
            <Container
                pt={
                    ['DatoCmsNumbersSection'].includes(previousSectionType)
                        ? { xs: imageMobileIsBottom ? 5 : 10, lg: 10 }
                        : hasHeroBefore
                            ? { xs: 20, lg: 20 }
                            : { xs: 10 }
                }
                pb={
                    ['DatoCmsPointsSection'].includes(nextSectionType)
                        ? { xs: 20, lg: 20 }
                        : {
                            xs: hasBackgroundColorScheme
                                ? 10
                                : isBigCta
                                    ? 20
                                    : 0,
                            lg: 10,
                        }
                }
                className={style.content}>
                <Row
                    className={`${titleDetached && !isBigCta
                        ? 'align-items-lg-end'
                        : 'align-items-lg-center'
                        } ${props.style === 'Video bottom' ? style.rowStyleB : ''
                        }`}>
                    {cmp}
                </Row>
            </Container>
        </section>
    );
}
