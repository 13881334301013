import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';


import { useTranslate } from 'soluble-gatsby-source';
import ThemeHeader from 'aplazame-design-system/src/components/Header';
import HeaderDropdown from '../../components/HeaderDropdown';
import NavbarBrandLink from '../../components/NavbarBrandLink';
import { HeaderAlert, Spinner } from 'aplazame-design-system';

import style from './header.module.scss';

const getLeftNav = (navs, onClick) =>
    navs
        .filter((thisNav) => thisNav[0].column > 0 && thisNav[0].column < 10)
        .map(([item]) => {
            let dropdownItems = navs.filter(
                ([subitem]) =>
                    subitem.column >= item.column * 10 &&
                    subitem.column < (item.column + 1) * 10
            );
            const isButton = !dropdownItems.length && item.type === 'button';
            dropdownItems = dropdownItems.map(subItem => {
                if (typeof window === 'undefined') {
                    return subItem;
                }
                return subItem.map(element => {
                    if (element.slug === "#b2b_login") {
                        element.slug = `${process.env.GATSBY_B2B_URL_LOGIN}`;
                    }
                    if (element.slug === "#b2c_login") {
                        element.slug = `${process.env.GATSBY_B2C_URL_LOGIN}`;
                    }
                    if (element.slug === "#external_signup") {
                        element.slug = `${process.env.GATSBY_EXTERNAL_URL_SIGNUP}?source=${window.location.pathname}`;
                    }
                    return element;
                });
            });
            return {
                ...item,
                Tag: dropdownItems.length ? 'button' : 'a',
                title: item.label,
                titleAlt: item.label,
                description: item.description,
                href:
                    item.label === 'Black Friday Game'
                        ? `${item.slug}`
                        : `/${item.slug}/`,
                type: dropdownItems.length ? 'list' : 'dark',
                size: 'sm',
                scheme: dropdownItems.length ? '' : item.type,
                extraClassName: [
                    style['left-nav__item'],
                    isButton ? style['left-nav__item--button'] : '',
                ].join(' '),
                linkClassName: style['left-nav__link'],
                dropdown: dropdownItems.length ? (
                    <HeaderDropdown items={dropdownItems} onClick={onClick} />
                ) : null,
                target: item.openInNewWindow ? '_blank' : null,
            };
        });

const getRightNavItems = (items, translate) => {
    const [hasMounted, setHasMounted] = useState(false);

    useEffect(() => {
        setHasMounted(true);
    }, []);

    if (!hasMounted) {
        return [];
    }

    let item = items.map((props) => {
        let dropdownItems = [];
        if (props.slug === "#/account/signin" || props.slug === "#/account/customers" || props.href === "#/account") {
            if (typeof window === 'undefined') {
                dropdownItems = [];
            } else {
                dropdownItems = [
                    [
                        {
                            "slug": `${process.env.GATSBY_B2C_URL_LOGIN}`,
                            "label": translate['login.b2c'].text,
                            "openInNewWindow": true
                        },
                        {
                            "slug": `${process.env.GATSBY_B2B_URL_LOGIN}`,
                            "label": translate['login.b2b'].text,
                            "openInNewWindow": true
                        }
                    ],
                ];
            }
        }
        if (props.slug === "#/account/signup" && typeof window !== 'undefined') {
            props.slug = `${process.env.GATSBY_EXTERNAL_URL_SIGNUP}?source=${window.location.pathname}`;
        }
        if (props.slug === "#external_signup" && typeof window !== 'undefined') {
            props.slug = `${process.env.GATSBY_EXTERNAL_URL_SIGNUP}?source=${window.location.pathname}`;
        }
        return ({
            extraClassName: `${style['right-nav__item']} ${props.loading ? style['right-nav__item--loading'] : ''
                } ${props.fake ? style['fake_item'] : ''} ${dropdownItems.length > 0 ? `${style['right-nav__dropdown']}` : ''}`,
            linkClassName: `${props.type !== 'primary' && props.type !== 'button'
                ? style['right-nav__item--outline']
                : ''
                }`,
            scheme: '',
            size: 'sm',
            // Tag: props.Tag ? props.Tag : 'a',
            Tag: dropdownItems.length ? 'button' : props.Tag ? props.Tag : 'a',
            title: props.loading ? (
                <Spinner forButton={true} />
            ) : props.title ? (
                props.title
            ) : props.label ? (
                props.label
            ) : (
                ' '
            ),
            titleAlt: props.title ? props.title : props.label ? props.label : ' ',
            href: props.href ? props.href : props.slug ? props.slug : '#',
            type:
                dropdownItems.length ? "list" :
                    props.type === 'primary' || props.type === 'button'
                        ? 'dark'
                        : 'outline-dark',
            onClick: props.onClick ? props.onClick : null,
            extraAttributes: props.loading ? { disabled: true } : {},
            target: props.openInNewWindow ? '_blank' : null,
            iconClass: props.iconClass || null,
            dropdown: dropdownItems.length ? (
                <HeaderDropdown items={dropdownItems} onClick={props.onClick} className={style.wrapperDropdown} />
            ) : null,
        })
    });
    return [
        {
            title: '',
            extraClassName: style.pipeSeparator,
        },
        ...item,
    ];
};

export default function Header(props) {
    const translate = useTranslate();
    const navbarRef = useRef();

    const [showAlert, setShowAlert] = useState(false);

    const [openMenuMobileLogin, setOpenMenuMobileLogin] = useState(false)

    const data = useStaticQuery(graphql`
        query {
            settings: datoCmsSetting {
                loginHash
            }
        }
    `);

    const getDropdownCollapseClassName = useCallback(
        (show, isFullyClosed, isFullyOpened) =>
            `${style.dropdown} ${show ? style['dropdown--open'] : ''} ${isFullyOpened ? style['dropdown--open-finish'] : ''
            }`,
        []
    );

    const closeNavbar = useCallback(() => {
        if (
            navbarRef &&
            navbarRef.current &&
            navbarRef.current.toggleCollapse
        ) {
            navbarRef.current.toggleCollapse(false)();
        }
    }, [navbarRef]);

    const rightNavItems = props.navs
        .filter((thisNav) => thisNav[0].column < 0)
        .map((thisNav) => thisNav[0]);

    if (rightNavItems.length === 1) {
        rightNavItems.push({
            title: '',
            href: `#`,
            fake: true,
        });
    }

    // useEffect(() => {
    //     if (typeof window === 'undefined') {
    //         return;
    //     }
    // }, []);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        if (props.extraParams && props.extraParams.alert) {
            const previouslyHidden =
                window.sessionStorage.getItem('headerAlert') === 'hide';
            if (!previouslyHidden) {
                setShowAlert(true);
            }
        }
    }, [setShowAlert, props.extraParams]);

    const closeAlert = useCallback(() => {
        setShowAlert(false);
        window.sessionStorage.setItem('headerAlert', 'hide');
    }, [setShowAlert]);

    return (
        <>
            {showAlert ? (
                <HeaderAlert onClose={closeAlert}>
                    {props.extraParams.alert}
                </HeaderAlert>
            ) : null}
            <ThemeHeader
                {...props}
                isFixed={true}
                dropdownCollapseClassName={getDropdownCollapseClassName}
                dropdownClassName={style['dropdown__inner']}
                dropdownOverlay={true}
                className={style.container}
                NavbarBrandTag={NavbarBrandLink}
                wrapperClassName={(isScrolled, navbarShown, navbarAnimating) =>
                    `${navbarShown ? style['nav--shown'] : ''} ${navbarAnimating ? style['nav--animating'] : ''
                    }`
                }
                navbarRef={navbarRef}
                navGroups={[
                    {
                        align: 'left',
                        items: getLeftNav(props.navs, closeNavbar),
                        className: style['left-nav'],
                    },
                    {
                        align: 'right',
                        items: getRightNavItems(
                            rightNavItems && rightNavItems.length
                                ? rightNavItems
                                : [
                                    {
                                        label: 'Acceder',
                                        href: `#${data.settings.loginHash}`,
                                        iconClass:
                                            'aplazicon-user-default-simple',
                                    },
                                    {
                                        title: '',
                                        href: `#`,
                                        fake: true,
                                    },
                                ]
                            , translate),
                        className: `${style['right-nav']} ${style['right-nav__default']
                            } ${style['right-nav--checked']}`,
                    },
                    {
                        align: 'right',
                        items: getRightNavItems(

                            [
                                {
                                    label: 'Acceder',
                                    href: `#`,
                                    onClick: () => {
                                        if (document.getElementById("toggleMenu").getAttribute('aria-expanded') === "true") {
                                            return;
                                        }
                                        if (document.documentElement.style.overflowY == "") {
                                            document.documentElement.style.overflowY = "hidden";
                                            window.document.body.style.overflowY = "hidden";
                                            document.getElementById("menuLogin").style.display = "block";
                                            document.getElementById("navLogoMenuClose").style.opacity = 0;
                                            document.getElementById("navLogoMenuOpen").style.opacity = 1;
                                            document.getElementById("fakeToggleMenu").style.display = "block";
                                        }
                                        setOpenMenuMobileLogin(!openMenuMobileLogin);

                                    },
                                    iconClass: "aplazicon-user-default-simple"
                                },
                            ]
                            , translate),
                        className: `${style['right-nav']} ${style['right-nav__mobile']
                            } d-lg-none ${style['right-nav--checked']}`,
                    },
                ]}
            />
            <>
                {typeof window !== 'undefined' && <div id="menuLogin" className={style.wrapperMenuLogin}>
                    <ul>
                        <li><a target='_blank' href={`${process.env.GATSBY_B2C_URL_LOGIN}`}>{translate['login.b2c'].text}</a></li>
                        <li><a target='_blank' href={`${process.env.GATSBY_B2B_URL_LOGIN}`}>{translate['login.b2b'].text}</a></li>
                    </ul>
                </div>}

            </>

        </>
    );
}
