import React from 'react';

import { Heading, Text, className } from 'soluble-design-system-base';

import style from './style.module.scss';
import textStyle from '../../scss/text.module.scss';

export default function PointComponent({
    icon,
    title,
    textNode,
    text,
    pretitleContent,
}) {
    return (
        <>
            {pretitleContent ? (
                pretitleContent
            ) : icon && icon.file && icon.file.publicURL ? (
                <img
                    src={icon.file.publicURL}
                    alt={icon.alt ? icon.alt : title}
                    {...className(`mb-3 mt-3 mt-lg-0 ${style.icon}`)}
                />
            ) : null}
            {title ? (
                <Heading
                    tag="h4"
                    className={`h5 mb-3 pr-md-4 font-weight-bold ${style.heading}`}>
                    {title}
                </Heading>
            ) : null}
            <Text
                tag={text ? 'div' : 'p'}
                className={`body pr-md-4 ${
                    textNode && textNode.contentWithoutWrapperUnderline
                        ? textStyle['child-underline']
                        : ''
                } ${style.text}`}>
                {text
                    ? text
                    : textNode && textNode.contentWithoutWrapperUnderline
                    ? textNode.contentWithoutWrapperUnderline
                    : ''}
            </Text>
        </>
    );
}
