import React, { useEffect, useState, useRef } from 'react';
import styles from './styles.module.scss';

/**
 * @param {string} url
 * @return {Object}
 */
const extractParams = (url) => {
    const urlParams = new URLSearchParams(url.split('?')[1]);
    const params = Object.fromEntries(urlParams.entries());
    return params;
};

const VideoComponent = ({ url, play }) => {
    const [video, setVideo] = useState(null);
    const [mounted, setMounted] = useState(false);
    const wrapperRef = useRef();

    useEffect(() => {
        if (typeof window === 'undefined' || !wrapperRef?.current) {
            return;
        }

        if (window.YT) {
            // Prevent problems if two videos in same page
            setTimeout(() => {
                setMounted(true);
            }, 1000);
            return;
        }

        if (!window.document.body.dataset.loadingYoutubeApi) {
            window.document.body.dataset.loadingYoutubeApi = true;

            window.onYouTubeIframeAPIReady = () => {
                setMounted(true);
            };

            let scr = document.createElement('script'),
                head =
                    document.head || document.getElementsByTagName('head')[0];
            scr.src = 'https://www.youtube.com/iframe_api';

            head.insertBefore(scr, head.firstChild);

            return () => {
                window.onYouTubeIframeAPIReady = null;
            };
        } else {
            let interval = null;
            const onInterval = () => {
                if (window.YT) {
                    setMounted(true);
                    if (interval) {
                        clearInterval(interval);
                    }
                }
            };

            interval = setInterval(onInterval, 1000);

            return () => {
                if (interval) {
                    clearInterval(interval);
                }
            };
        }
    }, [wrapperRef]);

    useEffect(() => {
        if (mounted && url && wrapperRef?.current) {
            const params = extractParams(url);
            new window.YT.Player(wrapperRef.current, {
                videoId: params?.v ?? url.split('v=')[1],
                playerVars: {
                    autoplay: Number.parseInt(params?.autoplay ?? 0),
                    start: Number.parseInt(params?.t ?? 0),
                },
                events: {
                    onReady: (e) => {
                        setVideo(e.target);
                    },
                },
            });
        }
    }, [mounted, url, wrapperRef]);

    useEffect(() => {
        if (video && play) {
            const params = extractParams(url);
            if (Number.parseInt(params?.autoplay) === 1) {
                video.playVideo();
            }
        }
    }, [video, play]);

    return (
        <div className={styles.videoContainer}>
            <div ref={wrapperRef} />
        </div>
    );
};

export default VideoComponent;
