import React, { memo } from 'react';

import CssClassNames from '../../scss/CssClassNames';
import moduledStyles from './styles.module.scss';
const { className } = new CssClassNames(moduledStyles, ['utility', 'text']);

export const Alert = memo(({ children, color = 'primary', mt, mb, extraClass = ''}) => {

    return (
        <div {...className(`alert alert-${color} 
            ${mt ? `mt-${mt}`: ''}
            ${mb ? `mb-${mb}`: ''}
            ${extraClass ? extraClass : ''}
        `)} 
        role="alert">
            {children}
        </div>
    );
});