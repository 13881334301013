import React, { useRef, useState, useEffect } from 'react';

import webpIsSupported from '../../utils/webpIsSupported';
import {
    Heading,
    Container,
    Row,
    Col,
    Text,
    className,
    isMobile,
} from 'soluble-design-system-base';
import { ButtonComponent } from 'aplazame-design-system';

import ImageComponent from '../ImageComponent';

import style from './style.module.scss';
import sectionStyle from '../../scss/section.module.scss';
import colorStyle from '../../scss/color.module.scss';

export default function HeroSection({
    colorScheme,
    whiteText,
    buttons,
    image,
    imageMobile,
    bgImage,
    bgImageWebp,
    bgImageMobile,
    bgImageMobileWebp,
    bgImageMobileNoOpacity,
    pretitle,
    pretitleTag,
    text,
    title,
    titleTag,
    textClassName,
    fullScreen,
    showVipAppLeadListForm,
    backgroundImageColorScheme,
    extraContent,
    FormVipAppLeadList,
    showFormSearchFaqs,
    FormSearchFaqs,
    fixPadding,
    smallerContainer,
    ThisButtonComponent = ButtonComponent,
}) {
    colorScheme = colorScheme?.toLowerCase();
    backgroundImageColorScheme = backgroundImageColorScheme?.toLowerCase();

    const isFirstLoad =
        typeof window !== 'undefined' &&
        !window.document.body.dataset.navigating;
    const firstDelay = isFirstLoad ? 300 : 100;
    const aboveTheFoldRisk =
        title &&
            title.length > 40 &&
            pretitle &&
            text &&
            buttons &&
            buttons.length
            ? true
            : false;

    const buttonRef = useRef();
    const formRef = useRef();

    const hasColorScheme = colorScheme !== 'none';
    const hasBackgroundImageColorScheme =
        backgroundImageColorScheme !== 'none' && !hasColorScheme;

    const [buttonOutsideViewport, setButtonOutsideViewport] = useState(false);
    const [formOutsideViewport, setFormOutsideViewport] = useState(false);

    const [bgDesktopImageSrc, setBgDesktopImageSrc] = useState(null);
    const [bgMobileImageSrc, setBgMobileImageSrc] = useState(null);

    useEffect(() => {
        if (
            isMobile() &&
            buttonRef &&
            buttonRef.current &&
            buttonRef.current.offsetTop > window.innerHeight * 0.7
        ) {
            setButtonOutsideViewport(true);
        }

        if (
            isMobile() &&
            formRef &&
            formRef.current &&
            formRef.current.offsetTop > window.innerHeight * 0.7
        ) {
            setFormOutsideViewport(true);
        }

        if (!!bgImage || !!bgImageMobile || !!bgImageWebp || !!bgImageMobileWebp) {
            webpIsSupported((webpSupport) => {
                const desktopImage =
                    webpSupport && bgImageWebp && bgImageWebp.file
                        ? bgImageWebp
                        : bgImage && bgImage.file
                            ? bgImage
                            : null;
                if (desktopImage) {
                    const img = new Image();
                    img.onload = () => {
                        if (desktopImage.file.childImageSharp?.gatsbyImageData?.images?.fallback?.src) {
                            setBgDesktopImageSrc(
                                desktopImage.file.childImageSharp.gatsbyImageData.images.fallback.src
                            );
                        } else {
                            setBgDesktopImageSrc(
                                desktopImage.file.childImageSharp.resize.src,
                            );
                        }

                    };
                    if (desktopImage.file.childImageSharp?.gatsbyImageData?.images?.fallback?.src) {
                        img.src = desktopImage.file.childImageSharp.gatsbyImageData.images.fallback.src;
                    } else {
                        img.src = desktopImage.file.childImageSharp.resize.src;
                    }

                }

                const mobileImage =
                    webpSupport && bgImageMobileWebp && bgImageMobileWebp.file
                        ? bgImageMobileWebp
                        : bgImageMobile && bgImageMobile.file
                            ? bgImageMobile
                            : null;
                if (mobileImage) {
                    const img = new Image();
                    img.onload = () => {
                        if (mobileImage.file.childImageSharp?.gatsbyImageData?.images?.fallback?.src) {
                            setBgMobileImageSrc(
                                mobileImage.file.childImageSharp.gatsbyImageData.images.fallback.src
                            );
                        } else {
                            setBgMobileImageSrc(
                                mobileImage.file.childImageSharp.resize.src,
                            );
                        }
                    };
                    if (mobileImage.file.childImageSharp?.gatsbyImageData?.images?.fallback?.src) {
                        img.src = mobileImage.file.childImageSharp.gatsbyImageData.images.fallback.src;
                    } else {
                        img.src = mobileImage.file.childImageSharp.resize.src;
                    }

                }
            });
        }
    }, [
        buttonRef,
        setButtonOutsideViewport,
        formRef,
        setFormOutsideViewport,
        bgImage,
        bgImageWebp,
        bgImageMobile,
        bgImageMobileWebp,
        setBgDesktopImageSrc,
        setBgMobileImageSrc,
    ]);

    return (
        <section
            {...className(
                [
                    style.section,
                    whiteText ? colorStyle['white-text'] : '',
                    sectionStyle['with-bg'],
                    sectionStyle['with-bg--none'],
                    sectionStyle[`with-bg--${colorScheme}`],
                    colorStyle[
                    `with-bg--${hasBackgroundImageColorScheme
                        ? backgroundImageColorScheme
                        : colorScheme
                    }`
                    ],
                    `pt-${!hasColorScheme ? 10 : pretitle ? 6 : 5}`,
                    'pt-lg-10',
                    'pb-lg-10',
                    'd-lg-flex align-items-lg-center',
                    `${fixPadding ? `pb-10` : `pb-lg-10`}`,
                    `${fixPadding
                        ? `pt-10`
                        : `pt-${!hasColorScheme ? 10 : pretitle ? 6 : 5}`
                    }`,
                    fullScreen ? style['section--full-screen'] : '',
                    smallerContainer ? style['section--small'] : '',
                    !hasColorScheme ? style['section--none'] : '',
                ].join(' '),
            )}>
            <div
                data-sal="fade"
                data-sal-easing="ease-out-quart"
                {...className(
                    `${!hasColorScheme && bgImageMobile
                        ? 'd-none d-lg-block'
                        : ''
                    } ${sectionStyle.bg} ${colorStyle.bg}`,
                )}
                style={{
                    '--sal-delay': `${firstDelay}ms`,
                    backgroundImage:
                        !hasColorScheme && bgImage && bgImage.file && bgImage.file.childImageSharp
                            ? `url("${bgDesktopImageSrc
                                ? bgDesktopImageSrc
                                : bgImage.file.childImageSharp.gatsbyImageData?.images.fallback.src
                            }")`
                            : 'none',
                }}
            />
            {bgImageMobile && bgImageMobile.file && !hasColorScheme ? (
                <div
                    {...className(
                        `d-lg-none ${sectionStyle.bg} ${colorStyle.bg}`,
                    )}
                    data-sal="fade"
                    data-sal-easing="ease-out-quart"
                    style={{
                        '--sal-delay': `${firstDelay}ms`,
                    }}>
                    <div
                        className={`${style['bg-content']} ${bgImageMobileNoOpacity
                            ? style['bg-content--no-opacity']
                            : ''
                            }`}
                        style={{
                            backgroundImage: `url("${bgMobileImageSrc
                                ? bgMobileImageSrc
                                : bgImageMobile.file.childImageSharp.gatsbyImageData?.images.fallback.src
                                }")`,
                        }}
                    />
                </div>
            ) : null}
            <Container>
                <Row
                    className={[
                        style.content,
                        pretitle ? style['content--with-pretitle'] : '',
                    ].join(' ')}>
                    <Col
                        col={{
                            lg: hasColorScheme
                                ? 6
                                : bgImage || bgImageMobile
                                    ? 7
                                    : 8,
                            xl:
                                !hasColorScheme && !bgImage && !bgImageMobile
                                    ? 8
                                    : 6,
                        }}>
                        {pretitle ? (
                            <Heading
                                tag={pretitleTag}
                                className={`mb-2 h4 ${style.preTitle} ${colorStyle['color--primary']}`}
                                data-sal="slide-up"
                                data-sal-easing="ease-out-quart"
                                style={{
                                    '--sal-delay': `${firstDelay + 300}ms`,
                                }}>
                                {pretitle}
                            </Heading>
                        ) : null}
                        <Heading
                            tag={titleTag}
                            className={`${showFormSearchFaqs ? 'h2' : 'h1'
                                } mb-3 ${aboveTheFoldRisk ? '' : 'mb-lg-5'} ${style.heading
                                } ${whiteText ? style.whiteText : ''}`}
                            data-sal="slide-up"
                            data-sal-easing="ease-out-quart"
                            style={{
                                '--sal-delay': `${firstDelay + (pretitle ? 600 : 300)
                                    }ms`,
                            }}>
                            {title}
                        </Heading>
                        <Text
                            tag="div"
                            className={`${style.text} ${textClassName ? textClassName : 'h3'
                                } ${style.text}`}
                            data-sal="slide-up"
                            data-sal-easing="ease-out-quart"
                            style={{
                                '--sal-delay': `${firstDelay + (pretitle ? 900 : 600)
                                    }ms`,
                            }}>
                            {text}
                        </Text>
                        {buttons && buttons.length ? (
                            <div
                                ref={buttonRef}
                                data-sal="slide-up"
                                data-sal-easing="ease-out-quart"
                                style={
                                    buttonOutsideViewport
                                        ? {}
                                        : {
                                            '--sal-delay': `${firstDelay +
                                                (pretitle ? 1200 : 900)
                                                }ms`,
                                        }
                                }
                                {...className(
                                    `text-center text-lg-left mt-5 ${aboveTheFoldRisk ? '' : 'mt-lg-10'
                                    } ${style.buttons}`,
                                )}>
                                <ThisButtonComponent
                                    buttons={buttons}
                                    blockMobile={true}
                                    dark={whiteText ? true : false}
                                    buttonClassName={`mb-3 mb-lg-0 mr-lg-3 mr-xl-5 ${style.button}`}
                                />
                            </div>
                        ) : null}
                        {showVipAppLeadListForm && FormVipAppLeadList ? (
                            <div
                                ref={formRef}
                                data-sal="slide-up"
                                data-sal-easing="ease-out-quart"
                                style={
                                    formOutsideViewport
                                        ? {}
                                        : {
                                            '--sal-delay': `${firstDelay +
                                                (pretitle ? 1200 : 900)
                                                }ms`,
                                        }
                                }>
                                <FormVipAppLeadList />
                            </div>
                        ) : null}
                        {showFormSearchFaqs &&
                            !showVipAppLeadListForm &&
                            FormSearchFaqs ? (
                            <div
                                ref={formRef}
                                data-sal="slide-up"
                                data-sal-easing="ease-out-quart"
                                style={
                                    formOutsideViewport
                                        ? {}
                                        : {
                                            '--sal-delay': `${firstDelay +
                                                (pretitle ? 1200 : 900)
                                                }ms`,
                                        }
                                }>
                                <FormSearchFaqs />
                            </div>
                        ) : null}
                        {extraContent ? (
                            <div
                                data-sal="slide-up"
                                data-sal-easing="ease-out-quart"
                                data-sal-delay={`xs-none--lg-${pretitle ? 1200 : 900
                                    }`}>
                                {extraContent}
                            </div>
                        ) : null}
                    </Col>
                </Row>
            </Container>
            {hasColorScheme && image ? (
                <div className={style['image']}>
                    <ImageComponent
                        image={image}
                        aspectRatio={0.841}
                        className={imageMobile ? 'd-none d-lg-block' : ''}
                    />
                    {imageMobile ? (
                        <ImageComponent
                            image={imageMobile}
                            aspectRatio={0.841}
                            className="d-block d-lg-none"
                        />
                    ) : null}
                </div>
            ) : null}
        </section>
    );
}
