import React, { useEffect, useState, useCallback } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { className as classNameFnc } from 'soluble-design-system-base';

export default function ImageComponent({
    store = false,
    image,
    alt = '',
    className,
    onLoad,
    aspectRatio,
    backGround,
    ...props
}) {
    const [show, setShow] = useState(
        image &&
        image.file &&
        image.file.childImageSharp &&
        (typeof window === 'undefined' ||
            image.file.childImageSharp.gatsbyImageData?.images?.fallback?.src ||
            image.file.childImageSharp.fluid ||
            (image.file.childImageSharp.resize && !onLoad)),
    );

    const onLoadImage = useCallback(() => {
        setShow(true);
        onLoad();
    }, [onLoad, setShow]);

    useEffect(() => {
        if (
            typeof window === 'undefined' ||
            !image ||
            !image.file ||
            !image.file.childImageSharp ||
            !onLoad
        ) {
            return;
        }

        if (image.file.childImageSharp.resize) {
            const img = new Image();
            img.onload = onLoadImage;
            img.src = image.file.childImageSharp.resize.src;
        }
    }, [image, onLoad, onLoadImage]);

    if (!alt && image && image.alt) {
        alt = image.alt;
    }

    if (store && image?.file?.childImageSharp === undefined) {
        return <GatsbyImage alt={alt} {...classNameFnc(className ? className : '')} image={image.gatsbyImageData} onLoad={onLoad ? onLoad : null} {...props} />
    }

    return show && image && image.file && image.file.childImageSharp.resize ? (
        <img
            {...classNameFnc(`img-fluid ${className ? className : ''}`)}
            src={image.file.childImageSharp.resize.src}
            alt={alt}
            {...props}
        />
    ) : image &&
        image.file &&
        image.file.childImageSharp &&
        (image.file.childImageSharp.gatsbyImageData) ? (
        <GatsbyImage alt={alt} {...classNameFnc(className ? className : '')} image={image.file.childImageSharp.gatsbyImageData} onLoad={onLoad ? onLoad : null} {...props} />
    ) : image && image.file && image.file.publicURL ? (
        <img
            {...classNameFnc(`img-fluid ${className ? className : ''}`)}
            src={image.file.publicURL}
            alt={alt}
            {...props}
        />
    ) : null;
}