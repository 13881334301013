export default function gTagInit(src) {
    const script = window.document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = src;
    script.addEventListener('load', function () {
        if (window['dataLayer'] && window['dataLayer']['push']) {
            window['dataLayer'].push({
                'gtm.start': new Date().getTime(),
                event: 'gtm.js',
            });
        }
    });
    window.document.querySelector('head').appendChild(script);
}
