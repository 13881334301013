export const DASHBOARD_ROUTES = {
    customers: process.env.GATSBY_CUSTOMERS_URL,
    vendors: process.env.GATSBY_VENDORS_URL
};

export const API_ROUTES = {
    'customers.login': {
        url: `${process.env.GATSBY_API_CUSTOMERS_URL}/accounts/signin?country=ES`,
        method: 'POST',
        credentials: true
    },
    'customers.phone-code': {
        url: `${process.env.GATSBY_API_CUSTOMERS_URL}/accounts/phone-code`,
        method: 'POST',
        credentials: true
    },
    'customers.me': {
        url: `${process.env.GATSBY_API_CUSTOMERS_URL}/accounts/me`,
        credentials: true
    },
    'customers.logout': {
        url: `${process.env.GATSBY_API_CUSTOMERS_URL}/accounts/signout`,
        method: 'POST',
        credentials: true
    },
    'customers.preferences.communications.get': {
        url: `${process.env.GATSBY_API_CUSTOMERS_URL}/accounts/preferences/communications-otp?token={token}`,
        method: 'GET'
    },
    'customers.preferences.communications.put': {
        url: `${process.env.GATSBY_API_CUSTOMERS_URL}/accounts/preferences/communications-otp?token={token}`,
        method: 'PUT'
    },
    'customers.preferences.email-resend': {
        url: `${process.env.GATSBY_API_CUSTOMERS_URL}/accounts/preferences/email-resend?token={token}`,
        method: 'POST'
    },
    'vendors.login': {
        url: `${process.env.GATSBY_API_VENDORS_URL}/signin`,
        method: 'POST',
        credentials: true
    },
    'vendors.password': {
        url: `${process.env.GATSBY_API_VENDORS_URL}/reset-password`,
        method: 'POST'
    },
    'vendors.password-change': {
        url: `${process.env.GATSBY_API_VENDORS_URL}/reset-password-confirm`,
        method: 'POST'
    },
    'vendors.logout': {
        url: `${process.env.GATSBY_API_VENDORS_URL}/signout`,
        method: 'POST',
        credentials: true
    },
    'vendors.me': {
        url: `${process.env.GATSBY_API_VENDORS_URL}/me`,
        credentials: true
    },
    'vendors.signup': {
        url: `${process.env.GATSBY_API_VENDORS_URL}/accounts`,
        method: 'POST',
        credentials: true
    },
    'vendors.activate': {
        url: `${process.env.GATSBY_API_VENDORS_URL}/{uuid}/validate-email?token={token}`,
        method: 'POST',
        credentials: true
    }
};