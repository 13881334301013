import { API_ROUTES } from './routes';

export default async function call(service, body = null, urlParams = null) {
    if (!API_ROUTES[service]) {
        throw new Error(`${service} does not exist.`);
    }

    let options = {};
    
    if (API_ROUTES[service].method) {
        options.method = API_ROUTES[service].method;
    }

    if (API_ROUTES[service].credentials) {
        options.credentials = 'include';
    }

    if (body) {
        options.body = body === 'string' ? body : JSON.stringify(body);
        options.headers = {
            'Content-Type': 'application/json'
        };
    }

    let url = API_ROUTES[service].url;

    if (urlParams) {
        Object.keys(urlParams).forEach(param => {
            url = url.replace(`{${param}}`, urlParams[param]);
        });
    }
    
    const response = await fetch(url, options);
    let json = null;

    try {
        json = await response.json();
    } catch (e) {}

    return {
        status: response.status,
        json
    }
}