import React from 'react';

import { className } from 'aplazame-design-system';
import TransitionLink from 'aplazame-design-system/src/components/TransitionLink';

export default function FooterLinkList({
    items,
    wrapperClassName = 'pt-2 mb-0',
    linkClassName = 'body pt-1 pb-1 d-block',
}) {
    return (
        <ul {...className(`list-unstyled ${wrapperClassName}`)}>
            {items
                .filter((link) => link.slug !== null)
                .map((link, i) => (
                    <li key={i}>
                        <TransitionLink
                            {...className(linkClassName)}
                            to={link.slug}
                            title={link.label}
                            target={link.openInNewWindow ? '_blank' : null}
                        >
                            {link.label}
                        </TransitionLink>
                    </li>
                ))}
        </ul>
    );
}
