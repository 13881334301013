import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import {
    Accordion,
    Container,
    Row,
    Col,
    className,
    Text,
    Heading,
} from 'aplazame-design-system';
import { useTranslate } from 'soluble-gatsby-source';
import FooterLinkList from '../../components/FooterLinkList';
import SocialIconsComponent from '../../components/SocialIconsComponent';

import style from './footer.module.scss';
import transitions from 'aplazame-design-system/src/scss/transitions.module.scss';
import textStyle from 'aplazame-design-system/src/scss/text.module.scss';
import TrustPilot from '../../components/TrustPilot';

const filterFooterNavs = (navs) =>
    navs.filter((navGroup) => navGroup.length && navGroup[0].column < 5);

const groupNavItems = (navs) =>
    navs
        .reduce((acc, navGroup) => {
            navGroup.forEach((item) => {
                if (!item.slug) {
                    acc.push({
                        title: (
                            <span
                                {...className(
                                    `${textStyle['underline--small']} text-uppercase subtitle`
                                )}
                            >
                                {item.label}
                            </span>
                        ),
                        label: item.label,
                        body: [],
                    });
                } else if (acc.length) {
                    acc[acc.length - 1].body.push(item);
                }
            });

            return acc;
        }, [])
        .map((group) => ({
            ...group,
            body: (
                <FooterLinkList
                    wrapperClassName={style.colWrapper}
                    linkClassName={style.colItems}
                    items={group.body}
                />
            ),
        }));

export default function Footer({ title, navs, extraParams, ...props }) {
    const translate = useTranslate();
    const footerNavs = filterFooterNavs(navs);
    const isMinimal = extraParams && extraParams.minimal ? true : false;

    const data = useStaticQuery(graphql`
        query {
            logo: file(
                sourceInstanceName: { eq: "img-aplazame-design-system" }
                name: { eq: "logo" }
            ) {
                publicURL
            }
            logoDark: file(
                sourceInstanceName: { eq: "img-aplazame-design-system" }
                name: { eq: "logo-dark" }
            ) {
                publicURL
            }
            settings: datoCmsSetting {
                twitterUrl
                linkedinUrl
                facebookUrl
                instagramUrl
            }
        }
    `);

    return (
        <footer className={style.footerWrapper}>
            {!isMinimal ? (
                <Container className={style.container}>
                    <Row className="">
                        {footerNavs.map((navGroup, i) => (
                            <Col
                                key={i}
                                className={`d-lg-block`}
                                col={{ lg: 3 }}
                            >
                                {groupNavItems([navGroup]).map((item, i) => (
                                    <div
                                        key={i}
                                        {...className(
                                            `mb-4 ${style.links} ${transitions['child-link']}`
                                        )}
                                    >
                                        <Heading
                                            tag="h5"
                                            className={`d-inline-block ${style.colLabel}`}
                                        >
                                            {item.label}
                                        </Heading>
                                        {item.body}
                                    </div>
                                ))}
                            </Col>
                        ))}
                        {/* <Col className="pt-4 pb-2 d-none d-lg-block">
                            <Text
                                tag="div"
                                className={`overline ${style['footer-text']} ${transitions['child-link-underline']}`}
                            >
                                {translate['footer.text'].md}
                            </Text>
                        </Col> */}
                    </Row>
                </Container>
            ) : null}
            <div {...className(`${style.copyright}`)}>
                <Container className={style.container}>
                    <hr className={style.separator} />
                    <Row>
                        <Col className="d-lg-flex flex-column">
                            <div className={style.footerLinksLegaTopWrapper}>
                                {navs
                                    .filter((navGroup) => navGroup[0].column === 5)
                                    .map((navGroup, i) => (
                                        <FooterLinkList
                                            key={i}
                                            items={navGroup}
                                            wrapperClassName={
                                                style['copyright__link-list']
                                            }
                                            linkClassName={`${style.copyrightItems}`}
                                        />
                                    ))}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="d-lg-flex align-items-lg-center" col={{ lg: 2 }} order={{ lg: 3 }}>
                            <hr className={style.separatorSocial} />
                            <div className={style.socialWrapper}>
                                <SocialIconsComponent
                                    wrapperClassName={`text-lg-right mb-0`}
                                    title={title}
                                    dark={true}
                                    twitterUrl={data.settings.twitterUrl}
                                    facebookUrl={data.settings.facebookUrl}
                                    linkedinUrl={data.settings.linkedinUrl}
                                    instagramUrl={data.settings.instagramUrl}
                                />
                            </div>

                        </Col>

                        <Col
                            className="d-lg-flex align-items-lg-center flex-column"
                            col={{
                                lg: 8,
                                // xl: isMinimal ? 5 : 4,
                            }}
                            order={{ lg: 2 }}
                        >
                            <TrustPilot />
                            <Text tag="div" className={`${style.copyText} text-center`}>
                                {translate['footer.copyright'].text}
                            </Text>

                        </Col>

                        <Col
                            className={`d-lg-flex align-items-lg-center flex-column ${style.footerLinksLegalWrapper}`}
                            col={{
                                lg: 2,
                                // xl: isMinimal ? 5 : 4,
                            }}
                            order={{ lg: 1 }}
                        >
                            {navs
                                .filter((navGroup) => navGroup[0].column === 5)
                                .map((navGroup, i) => (
                                    <FooterLinkList
                                        key={i}
                                        items={navGroup}
                                        wrapperClassName={
                                            style['copyright__link-list']
                                        }
                                        linkClassName={`${style.copyrightItems}`}
                                    />
                                ))}
                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>
    );
}
