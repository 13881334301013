import React from 'react';

import { className, Spinner } from 'aplazame-design-system';


export default function FormSpinner(props) {
    return (
        <div {...className(`${props.className ? props.className : ''} d-flex justify-content-center align-items-center`)}>
            <Spinner
                size="2.5rem"
                color="primary"
                borderSmall={false}
            />
        </div>
    );
}