import React, { forwardRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import CustomLink from '../Link';
import { Container, Navbar, className } from 'soluble-design-system-base';

import style from './style.module.scss';

const ConditionalContainer = ({ isContainer, children }) => {
    return isContainer ? (
        <Container {...className('pl-0 pr-0')}>{children}</Container>
    ) : (
        children
    );
};

export default forwardRef(function Header(
    {
        isContainer = false,
        title,
        hideOnScroll = false,
        defaultIsFixed = false,
        isFixed = false,
        isDark = false,
        isTransparent = false,
        navGroups = [],
        navs = [],
        alignItems = 'right',
        navLinkClass = null,
        navLinkActiveClass = null,
        dropdownClassName = null,
        dropdownCollapseClassName = null,
        wrapperClassName = null,
        durationCollapse = null,
        navbarExtraContent = null,
        logo = null,
        logoWhite = null,
        logoDom = null,
        customTogglerIcon = null,
        customTogglerIconOpen = null,
        customToggler = null,
        className: cls = null,
        dropdownOverlay,
        onDropdownOpen,
        onDropdownClose,
        NavbarBrandTag,
        navbarBrandHref,
        navbarRef,
        blockScrollStyle,
    },
    ref,
) {
    const data = useStaticQuery(graphql`
        {
            logo: file(
                sourceInstanceName: { eq: "img-aplazame-design-system" }
                name: { eq: "logo" }
            ) {
                publicURL
            }
            logoWhite: file(
                sourceInstanceName: { eq: "img-aplazame-design-system" }
                name: { eq: "logo-dark" }
            ) {
                publicURL
            }
        }
    `);

    const navsItems = navs.map((column) =>
        column.map((item, i) => {
            return i === 0
                ? item
                : {
                    href: item.slug,
                    title: item.label,
                    titleAlt: item.label,
                    Tag: CustomLink,
                    scheme: 'gatsby',
                    target: item.openInNewWindow ? '_blank' : null,
                };
        }),
    );

    return (
        <header
            {...className(`${isDark ? 'bg-dark' : ''} ${style.header}`)}
            ref={ref}>
            <ConditionalContainer isContainer={isContainer}>
                <Navbar
                    Tag={CustomLink}
                    NavbarBrandTag={NavbarBrandTag}
                    isFixed={isFixed || defaultIsFixed}
                    isTransparent={isTransparent}
                    dark={isDark}
                    hideOnScroll={hideOnScroll}
                    href={navbarBrandHref ? navbarBrandHref : '/'}
                    scheme="gatsby"
                    logo={logo ? logo : data.logo ? data.logo.publicURL : null}
                    logoNavbarShown={
                        logoWhite
                            ? logoWhite
                            : data.logoWhite
                                ? data.logoWhite.publicURL
                                : null
                    }
                    logoAlt={title}
                    logoDom={logoDom}
                    dropdownClassName={dropdownClassName}
                    dropdownCollapseClassName={dropdownCollapseClassName}
                    dropdownOverlay={dropdownOverlay}
                    onDropdownOpen={onDropdownOpen}
                    onDropdownClose={onDropdownClose}
                    brandParams={{
                        'aria-current': 'page',
                        'aria-label': title,
                    }}
                    extraClass={`${cls ? cls : ''}`}
                    blockScrollStyle={blockScrollStyle}
                    {...(wrapperClassName && {
                        wrapperClassName: wrapperClassName,
                    })}
                    {...(navbarExtraContent && { navbarExtraContent })}
                    {...{ durationCollapse }}
                    {...{ customTogglerIcon }}
                    {...{ customTogglerIconOpen }}
                    {...{ customToggler }}
                    {...(navbarRef && { ref: navbarRef })}
                    navs={
                        navGroups && navGroups.length
                            ? navGroups
                            : [
                                {
                                    align: alignItems,
                                    items: [
                                        ...navsItems.map(
                                            ([firstNav, ...restNavs]) => {
                                                return {
                                                    Tag: CustomLink,
                                                    scheme: 'gatsby',
                                                    title: firstNav.label,
                                                    titleAlt: firstNav.label,
                                                    ...firstNav,
                                                    ...(firstNav.openInNewWindow && {
                                                        target: '_blank',
                                                    }),
                                                    ...(firstNav.slug && {
                                                        href: firstNav.slug,
                                                    }),
                                                    ...(navLinkClass && {
                                                        extraClassName: navLinkClass,
                                                    }),
                                                    ...(navLinkActiveClass && {
                                                        activeClassName: navLinkActiveClass,
                                                    }),
                                                    ...(firstNav.type && {
                                                        extraClassName:
                                                            firstNav.className ||
                                                            style[
                                                                `nav-item-${firstNav.type}`
                                                                ],
                                                        linkClassName:
                                                            style[
                                                                `nav-link-${firstNav.type}`
                                                                ],
                                                    }),
                                                    ...(restNavs &&
                                                        restNavs.length >
                                                        0 && {
                                                            subitems: restNavs,
                                                            type: 'list',
                                                            partiallyActive: true,
                                                        }),
                                                };
                                            },
                                        ),
                                    ],
                                },
                            ]
                    }
                />
            </ConditionalContainer>
        </header>
    );
});
