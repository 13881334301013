import { useRef, useEffect } from 'react';

export function getUrlParam(param) {
    if (typeof window === 'undefined' || !window.location.search) {
        return null;
    }

    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
}

export function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    });

    return ref.current;
}

export const fadeInContainerWithStagger = {
    hidden: {
        opacity: 0,
    },
    visible: {
        opacity: 1,
        transition: {
            duration: 0.4,
            type: 'tween',
            ease: 'easeIn',
            when: 'beforeChildren',
            staggerChildren: 0.4,
        },
    },
};

export const fadeInUp = {
    hidden: {
        opacity: 0,
        y: 40,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.8,
        },
    },
};
