import React from 'react';

import {
    Container,
    Row,
    Col,
    Heading,
    className,
    Text,
} from 'aplazame-design-system';

import ButtonComponent from '../ButtonComponent';
import PointComponent from '../PointComponent';

import style from './style.module.scss';
import textStyle from '../../scss/text.module.scss';
import colorStyle from '../../scss/color.module.scss';
import sectionStyle from '../../scss/section.module.scss';

export default function PointsSection({
    previousSectionType,
    title,
    titleTag,
    button,
    points,
    addNumbers,
    colorScheme,
    leftAlign,
    pt,
    ThisButtonComponent = ButtonComponent,
}) {
    colorScheme = colorScheme?.toLowerCase();
    const pointsHaveTitle = points.find((p) => !!p.title);
    const hasColorScheme = colorScheme !== 'none';

    return (
        <section
            {...className(
                [
                    colorStyle[`with-bg--${colorScheme}`],
                    sectionStyle['with-bg'],
                    sectionStyle['with-bg--none'],
                    hasColorScheme ? '' : '',
                ].join(' '),
            )}>
            {hasColorScheme ? (
                <div
                    data-sal="fade"
                    data-sal-easing="ease-out-quart"
                    className={`${sectionStyle.bg} ${colorStyle.bg}`}
                />
            ) : null}
            <Container
                pt={
                    pt
                        ? pt
                        : [
                            'DatoCmsFeatureSection',
                            'DatoCmsCtaSection',
                        ].includes(previousSectionType) && !title
                            ? {
                                xs: pointsHaveTitle ? 5 : 0,
                                lg: pointsHaveTitle ? 5 : 5,
                            }
                            : { xs: 5 }
                }
                pb={{
                    xs: pointsHaveTitle ? 5 : 0,
                    lg: pointsHaveTitle ? 5 : 5,
                }}
                className={style.content}>
                {title ? (
                    <Row>
                        <Col
                            col={{ lg: 8 }}
                            offset={{ lg: leftAlign ? 0 : 2 }}
                            className={leftAlign ? '' : 'text-lg-center'}>
                            <Heading
                                tag={titleTag}
                                className={`h2 mt-5 mt-lg-10 mb-${addNumbers ? 10 : 9
                                    } mb-lg-${addNumbers ? 15 : 12} ${textStyle.gradual
                                    } ${style.heading}`}
                                data-sal="slide-up"
                                data-sal-easing="ease-out-quart">
                                {title}
                            </Heading>
                        </Col>
                    </Row>
                ) : null}
                <Row>
                    {points.map((point, i) => (
                        <Col
                            key={i}
                            pb={{
                                xs: addNumbers ? 10 : 5,
                                lg: 10,
                            }}
                            col={{
                                md: 6,
                                lg: 4,
                            }}
                            offset={{
                                lg: leftAlign && (!i || i % 2 === 0) ? 3 : 0,
                            }}
                            data-sal="slide-up"
                            data-sal-easing="ease-out-quart"
                            data-sal-delay={`xs-none--lg-${(i % (leftAlign ? 2 : 3)) * 100
                                }`}>
                            <PointComponent
                                {...point}
                                pretitleContent={
                                    addNumbers ? (
                                        <Text
                                            className={`h2 ${textStyle.gradual} ${colorStyle.color} d-block mb-2`}
                                            tag="span">{`0${i + 1}.`}</Text>
                                    ) : null
                                }
                            />
                        </Col>
                    ))}
                    {button ? (
                        <Col
                            pb={{ xs: 5, lg: 10 }}
                            className="text-center"
                            data-sal="slide-up"
                            data-sal-easing="ease-out-quart"
                            data-sal-delay="xs-none--lg-300">
                            <div className={style.wrapperButton}>
                                <ThisButtonComponent button={button} />
                            </div>
                        </Col>
                    ) : null}
                </Row>
            </Container>
        </section>
    );
}
