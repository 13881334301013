import React from 'react';

import ButtonComponent from '../ButtonComponent';
import TransitionLink from '../TransitionLink';

export default function GatsbyTransitionButtonComponent(props) {
    return (
        <ButtonComponent
            {...props}
            defaultTag={TransitionLink}
        />
    );
}