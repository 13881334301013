import call from './call';
import { DASHBOARD_ROUTES } from './routes';

const formatErrorResponse = (error) => {
    let response = {};

    if (error && error.type === 'ApiValidationException' && error.fields) {
        response = Object.keys(error.fields).reduce((acc, item) => {
            const baseField = error.fields[item];
            acc[item] = Array.isArray(baseField)
                ? baseField.join(', ')
                : Object.values(baseField)
                      .map((baseFieldArr) => baseFieldArr.join(', '))
                      .join(', ');

            return acc;
        }, {});
    } else if (error && error.message) {
        response.non_field_errors = error.message;
    }

    return response;
};

const unavailableError = () => {
    return formatErrorResponse({ message: 'service_unavailable', type: 'service_unavailable' })
}

export const customersLogin = (document_id, number) =>
    new Promise((resolve, reject) => {
        call('customers.login', {
            document_id,
            phone: {
                prefix: '34',
                number,
            },
        }).then(({ status, json }) => {
            if ([200, 201, 204].includes(status)) {
                return resolve(json ? json : {});
            }

            if ([429].includes(status)) {
                const error = { is_trottle_error: true }
                return reject(error)
            }

            if ([503].includes(status)) {
                return reject(unavailableError());
            }

            reject(formatErrorResponse(json ? json.error : {}));
        });
    });

export const customersPhoneCode = (code, number, document_id) =>
    new Promise((resolve, reject) => {
        call('customers.phone-code', {
            code,
            document_id,
            phone: {
                prefix: '34',
                number,
            },
        }).then(({ status, json }) => {
            if ([200, 201, 204].includes(status)) {
                window.location.hash = '_';
                window.location.href = DASHBOARD_ROUTES.customers;
                return resolve(null);
            }

            if ([429].includes(status)) {
                const error = { is_trottle_error: true }
                return reject(error)
            }

            let error = {};

            if (json.error && json.error.message) {
                error.non_field_errors = json.error.message;
            }

            reject(error);
        });
    });

export const customersMe = () =>
    new Promise((resolve, reject) => {
        call('customers.me')
            .then(({ status }) => {
                if (status === 401) {
                    reject(null);
                } else {
                    if ([503].includes(status)) {
                        return reject(unavailableError());
                    }
                    resolve(null);
                }
            })
            .catch(() => {
                reject(null);
            });
    });

export const customersLogout = () =>
    new Promise((resolve) => {
        call('customers.logout').then(resolve);
    });

export const customersGetCommunicationPreferences = (token) =>
    new Promise((resolve, reject) => {
        call('customers.preferences.communications.get', null, { token })
            .then(({ status, json }) => {
                if (status === 401) {
                    reject(null);
                } else {
                    resolve(json);
                }
            })
            .catch(() => {
                reject(null);
            });
    });

    export const customersPutCommunicationPreferences = (token, body) =>
    new Promise((resolve, reject) => {
        call('customers.preferences.communications.put', body, { token })
            .then(({ status, json }) => {
                if (status === 401) {
                    reject(null);
                } else {
                    resolve(null);
                }
            })
            .catch(() => {
                reject(null);
            });
    });

export const customersResendEmailPreferences = (token) =>
    new Promise((resolve, reject) => {
        call('customers.preferences.email-resend', null, { token })
            .then(() => {
                resolve(null);
            })
            .catch(() => {
                resolve(null);
            });
    });

export const vendorsLogin = (identification, password) =>
    new Promise((resolve, reject) => {
        call('vendors.login', {
            identification,
            password,
        }).then(({ status, json }) => {
            if ([200, 201, 204].includes(status)) {
                window.location.hash = '_';
                window.location.href = DASHBOARD_ROUTES.vendors;
                return resolve(null);
            }

            if ([503].includes(status)) {
                return reject(unavailableError());
            }
            
            reject(formatErrorResponse(json ? json.error : {}));
        });
    });

export const vendorsRecoverPassword = (email) =>
    new Promise((resolve, reject) => {
        call('vendors.password', {
            email,
        }).then(({ status, json }) => {
            if ([503].includes(status)) {
                return reject(unavailableError());
            }

            resolve(null);
        });
    });

export const vendorsChangePassword = (uid, new_password1, new_password2) =>
    new Promise((resolve, reject) => {
        call('vendors.password-change', {
            uid,
            new_password1,
            new_password2,
        }).then(({ status, json }) => {
            if ([200, 201, 204].includes(status)) {
                return resolve(null);
            }

            if ([503].includes(status)) {
                return reject(unavailableError());
            }

            reject(formatErrorResponse(json ? json.error : {}));
        });
    });

export const vendorsMe = () =>
    new Promise((resolve, reject) => {
        call('vendors.me')
            .then(({ status }) => {
                if (status === 401) {
                    reject(null);
                } else {
                    if ([503].includes(status)) {
                        return reject(unavailableError());
                    }
                    resolve(null);
                }
            })
            .catch(() => {
                reject(null);
            });
    });

export const vendorsLogout = () =>
    new Promise((resolve) => {
        call('vendors.logout').then(resolve);
    });

export const vendorsSignup = (body) =>
    new Promise((resolve, reject) => {
        call('vendors.signup', body).then(({ status, json }) => {
            if (status >= 200 && status < 300) {
                if (window['dataLayer']) {
                    window['dataLayer'].push({
                        event: `vendors-signup-success`,
                    });
                }
                resolve(json ? json : null);
            } else {
                if ([503].includes(status)) {
                    return reject(unavailableError());
                }
                reject(json);
            }
        });
    });

export const vendorsActivate = (uuid, token) =>
    new Promise((resolve, reject) => {
        call('vendors.activate', null, {
            uuid,
            token,
        }).then(({ status }) => {
            if (status >= 200 && status < 300) {
                resolve(null);
            } else {
                reject(null);
            }
        });
    });
