import React from 'react';


import style from './style.module.scss';

export default function TrustPilot(props) {

    return (
        <div className={style.wrapper}>



            <div className="trustpilot-widget" data-locale="es-ES" data-template-id="5419b6ffb0d04a076446a9af" data-businessunit-id="5d679c84e490e600019d8b82" data-style-height="20px" data-style-width="100%" data-theme="light">
                <a href="https://es.trustpilot.com/review/aplazame.com" target="_blank" rel="noopener">Trustpilot</a>
            </div>



            <img alt={"confianza online"} className={style.wrapperImage} src={'/confianza-color-horizontal.png'} />
        </div >
    );
}
