import React from 'react';
import { Link as GatsbyLink } from 'gatsby';

// TODO: Quitar este link, ya tenemos el NavItem

export default function Link({to = "/", children, activeClassName, ...props}) {
    if (!to) {
        return null;
    }

    let finalLink = to;
    let onClick = null;
  
    if (finalLink.startsWith('/')) {
        finalLink = finalLink.substr(1);
    }

    const isGatsby = !(finalLink.startsWith('http') || finalLink.startsWith('mailto'));

    if (isGatsby && finalLink && !finalLink.endsWith('/')) {
        finalLink = `${finalLink}/`;
    }
    
    if (isGatsby && props.target === '_blank') {
        onClick = e => {
            e.preventDefault();
            window.open(`/${finalLink}`);

            if (props.onClick) {
                props.onClick(e);
            }
        }
    }

    const link = isGatsby ? (
        <GatsbyLink  
            to={`/${finalLink}`}
            activeClassName={activeClassName}
            {...props} 
            onClick={onClick}
        >
            {children}
        </GatsbyLink>
    ) : (
        <a href={finalLink} target="_blank" rel="noopener noreferrer" {...props}>
            {children}
        </a>
    )

    return link;
}
