import React, { forwardRef } from 'react';

import { useTranslate } from 'soluble-gatsby-source';

import PasswordForceDecoupled from '../PasswordForceDecoupled';


export default forwardRef(function PasswordForce(props, ref) {
    const translate = useTranslate();

    return (
        <PasswordForceDecoupled
            ref={ref}
            {...props}
            translate={translate}
        />
    )
});