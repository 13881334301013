import React, { useEffect, useState} from 'react';

import { className } from 'soluble-design-system-base';

import style from './style.module.scss';


function BaseIcon(props) {
    const [ enter, setEnter ] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setEnter(true);
        }, props.timeout ? props.timeout : 0);
    }, [ props.timeout, setEnter ]);

    return (
        <div {...className(`text-center ${props.className ? props.className : ''}`)}>
            <div 
                className={[
                    style.icon,
                    style[`icon__${props.type}`],
                    enter ? style['icon--enter'] : ''
                ].join(' ')}
            />
        </div>
    )
}

export function SuccessIcon(props) {
    return (
        <BaseIcon
            {...props}
            type="success"
        />
    );
};

export function ErrorIcon(props) {
    return (
        <BaseIcon
            {...props}
            type="error"
        />
    );
}

export function WarningIcon(props) {
    return (
        <BaseIcon
            {...props}
            type="warning"
        />
    );
}