import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { className, ModalBase } from 'aplazame-design-system';
import { useTranslate } from 'soluble-gatsby-source';

import TabComponent from '../TabComponent';
import ModalPhoneCode from '../ModalPhoneCode';
import ModalMaintenance from '../ModalMaintenance';
import FormLoginCustomers from '../FormLoginCustomers';
import FormLoginVendors from '../FormLoginVendors';
import FormSubmit from '../FormSubmit';

import style from './style.module.scss';

export default function ModalLogin({ activeTab = 0 }) {
    const translate = useTranslate();

    const modalRef = useRef();
    const formLoginCustomersRef = useRef();
    const formLoginVendorsRef = useRef();

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [documentId, setDocumentId] = useState(null);
    const [phone, setPhone] = useState(null);
    const [code, setCode] = useState(null);
    const [maintenance, setMaintenance] = useState(null);

    const data = useStaticQuery(graphql`
        query {
            settings: datoCmsSetting {
                loginB2cHash
                loginB2bHash
            }
        }
    `);

    useEffect(() => {
        if (window.isMaintenanceMode) {
            setMaintenance(true);
        } else {
            if (modalRef.current) {
                modalRef.current.toggle();
            }
        }
    }, []);

    useEffect(() => {
        setError(null);
    }, [activeTab]);

    const onToggle = useCallback((show) => {
        if (typeof window !== 'undefined' && !show) {
            window.location.hash = '_';
        }
    }, []);

    const submitForm = useCallback(
        (e) => {
            if (e) {
                e.preventDefault();
            }

            if (activeTab === 0) {
                formLoginCustomersRef.current.submitForm();
            } else if (activeTab === 1) {
                formLoginVendorsRef.current.submitForm();
            }
        },
        [activeTab]
    );

    const onFormLoginCustomerSuccess = useCallback(
        ({ documentId, phone, codesms }) => {
            setDocumentId(documentId);
            setPhone(phone);

            if (codesms) {
                setCode(codesms);
            }
        },
        []
    );

    const onError = useCallback(setError, []);

    const onTogglePhoneModal = useCallback((show) => {
        if (!show) {
            setPhone(null);
            setCode(null);
        }
    }, []);

    const toggleMaintenanceModal = useCallback((show) => {
        setMaintenance(show);
        if (!show) {
            if (typeof window !== 'undefined') {
                window.location.hash = '_';
            }
        }
    }, []);

    return (
        <>
            <ModalBase
                wrapperClassName={style.wrapperModal}
                parentRef={modalRef}
                onToggle={onToggle}
                title={translate['modal.login.title'].text}
                titleDetached={true}
                footer={
                    <FormSubmit
                        text={translate['modal.login.submit'].text}
                        error={error}
                        loading={loading}
                        allowSubmit={!phone}
                        onClick={submitForm}
                    />
                }
            >
                <TabComponent
                    activeClassName={style.active}
                    className={style.tab}
                    items={[
                        {
                            active: activeTab === 0,
                            title: translate['modal.login.b2c.title'].text,
                            href: `#${data.settings.loginB2cHash}`,
                        },
                        {
                            active: activeTab === 1,
                            title: translate['modal.login.b2b.title'].text,
                            href: `#${data.settings.loginB2bHash}`,
                        },
                    ]}
                />
                <div
                    {...className(
                        `${style['tab__pane']} ${
                            activeTab === 0 ? '' : 'd-none'
                        }`
                    )}
                >
                    <FormLoginCustomers
                        ref={formLoginCustomersRef}
                        onToggleLoading={setLoading}
                        showMaintenanceModal={toggleMaintenanceModal}
                        onSuccess={onFormLoginCustomerSuccess}
                        onError={onError}
                    />
                </div>
                <div
                    {...className(
                        `${style['tab__pane']} ${
                            activeTab === 1 ? '' : 'd-none'
                        }`
                    )}
                >
                    <FormLoginVendors
                        ref={formLoginVendorsRef}
                        onToggleLoading={setLoading}
                        showMaintenanceModal={toggleMaintenanceModal}
                        onError={onError}
                    />
                </div>
            </ModalBase>

            {phone ? (
                <ModalPhoneCode
                    documentId={documentId}
                    phone={phone}
                    code={code}
                    onToggle={onTogglePhoneModal}
                />
            ) : null}

            {maintenance ? (
                <ModalMaintenance onToggle={toggleMaintenanceModal} />
            ) : null}
        </>
    );
}
