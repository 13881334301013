import React, { useEffect, useState, forwardRef, useImperativeHandle, useCallback } from 'react';

import { className, Text } from 'aplazame-design-system';

import style from './style.module.scss';


const checkPassword = (str = '') => {
    const hasLower = /[a-z]/.test(str);
    const hasUpper = /[A-Z]/.test(str);
    const hasNumber = /[0-9]/.test(str);
    const hasSpecialChar = /[!@#$%^&*\.,;:\(\)\[\]\{\}\/\\\-\_]/.test(str);
    const hasLength = str.length > 7;

    return {
        hasLower,
        hasUpper,
        hasNumber,
        hasSpecialChar,
        hasLength
    }
}

export default forwardRef(function PasswordForceDecoupled({
    password,
    translate,
    ...props
}, ref) {
    const [ passwordForce, setPasswordForce ] = useState(checkPassword());

    useEffect(() => {
        setPasswordForce(checkPassword(password));
    }, [ password ]);

    const passwordForceItems = {
        hasUpper: translate['modal.password-change.force.uppercase'].text,
        hasLower: translate['modal.password-change.force.lowercase'].text,
        hasNumber: translate['modal.password-change.force.number'].text,
        hasLength: translate['modal.password-change.force.length'].text,
        hasSpecialChar: translate['modal.password-change.force.special-character'].text,
    };

    const validate = useCallback(
        (str = null) => {
            const thisPasswordForce = str ? checkPassword(str) : passwordForce;
            const isPasswordOk = !Object.values(thisPasswordForce).filter(isOk => !isOk).length;

            return isPasswordOk
        },
        [ passwordForce ]
    );

    useImperativeHandle(ref, () => ({
        validate
    }))
    
    return (
        <div {...className(`${props.className ? props.className : ''}`)}>
            <Text tag="div" className="body mb-2">
                {translate['modal.password-change.force.title'].md}
            </Text>
            <ul {...className(`${style.list} list-inline`)}>
                {Object.keys(passwordForceItems).map((itemKey, i) => (
                    <li key={i} className={style.item}>
                        <Text tag="span" className={`body ${style['item__label']} ${passwordForce[itemKey] ? style['item__label--active'] : ''}`}>
                            {passwordForceItems[itemKey]}
                        </Text>
                    </li>
                ))}
            </ul>
        </div>
    );
});