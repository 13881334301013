import React from 'react';

import { Alert } from 'aplazame-design-system';

import style from './style.module.scss';


export default function FormAlert({
    children
}) {
    return (
        <Alert
            color="danger"
            extraClass={`${style.alert} overline`}
        >
            {children}
        </Alert>
    )
}