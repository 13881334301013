module.exports = themeOptions => {
    const pathsByLanguage = themeOptions.pathsByLanguage || {};
    const language = themeOptions.language || {
        es: '',
        en: 'en'        
    };
    const primaryLanguage = themeOptions.primaryLanguage || 'es';
    const basePath = themeOptions.basePath || `/`;
    const templatesPath = themeOptions.templatesPath || `src/templates`;
    const contentPath = themeOptions.contentPath || `content/pages`;
    const assetPath = themeOptions.assetPath || `static/assets`;

    return {
        language,
        pathsByLanguage,
        primaryLanguage,
        basePath,
        contentPath,
        assetPath,
        templatesPath
    }
}