import React, { useRef, useEffect, useCallback } from 'react';

import { Text, Button, ModalBase } from 'aplazame-design-system';
import { useTranslate } from 'soluble-gatsby-source';


export default function ModalSignupSuccess({
    onToggle,
    text,
    qaUrl
}) {
    const translate = useTranslate();

    const modalRef = useRef();
    
    useEffect(() => {
        modalRef.current.toggle();
    }, []);

    const onClick = useCallback(
        () => {
            modalRef.current.toggle();
        },
        [ modalRef ]
    );
    
    return (
        <ModalBase
            parentRef={modalRef}
            onToggle={onToggle}
            title={translate['modal.signup.b2b.success.title'].text}
            size="md"
            success={true}
            footer={(
                <>
                    <Button 
                        type="primary"
                        block={true}
                        Tag="button"
                        onClick={onClick}
                    >
                        {translate['modal.signup.b2b.success.accept'].text}
                    </Button>
                    {qaUrl && process.env.ENV !== 'production' ? (
                        <div qa="signup_link">
                            <a href={qaUrl}>Completar registro</a>
                        </div>
                    ) : null}
                </>
            )}
        >
            <Text tag="div" className="body text-center pb-5 pb-lg-11">
                {text}
            </Text>
        </ModalBase>
    );
}