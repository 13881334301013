import React, { useState, useEffect } from 'react';

import { Breadcrumb as BaseBreadcrumb } from 'soluble-design-system-base';

import style from './style.module.scss';
import transitions from '../../scss/transitions.module.scss';


export default function Breadcrumb({ links, firstFavicon, className }) {
    const [ finalLinks, setFinalLinks ] = useState([]);

    useEffect(
        () => {
            if (!links || !links.length) {
                setFinalLinks([]);
                return;
            }

            let newLinks = [ ...links.map(link => ({ ...link })) ];

            if (firstFavicon) {
                newLinks[0].titleAlt = newLinks[0].title;
                newLinks[0].title = (
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24">
                        <path fillRule="evenodd" d="M10.923 4L8.603 12.4 12.033 12.4 14.252 4 16.791 13.616 4.745 13.616 4 16.314 7.522 16.314 6.504 20 10.027 20 11 16.314 17.504 16.314 18.477 20 22 20 17.582 4z" />
                    </svg>
                );
            } else if (typeof newLinks[0].title !== 'string') {
                newLinks[0].title = newLinks[0].titleAlt;
            }

            setFinalLinks(newLinks);
        },
        [ links, firstFavicon, setFinalLinks ]
    );

	return (
        <BaseBreadcrumb
            links={finalLinks}
            wrapperClassName={`${style.breadcrumb} ${firstFavicon ? style['breadcrumb--first-favicon'] : ''} ${className ? className : ''}`}
            liClassName={style.li}
            linkClassName={transitions.link}
        />
    );
}