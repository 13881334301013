module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://blog.aplazame.com/graphql","verbose":true,"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":99999999,"hardCacheData":false},"debug":{"graphql":{"writeQueriesToDisk":true,"showQueryVarsOnError":false,"showQueryOnError":false,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"type":{"Post":{"limit":5000},"Page":{"exclude":true},"Menu":{"exclude":true},"Taxonomy":{"exclude":true},"Comment":{"exclude":true},"MenuItem":{"exclude":true},"ContentType":{"exclude":true},"Tag":{"exclude":true},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"schema":{"perPage":10,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","timeout":30000,"requestConcurrency":15,"previewRequestConcurrency":5},"modelPages":["Post"],"supports":{"wpml":false,"acf":false,"yoast":false,"rankmath":true},"catchLinks":true},
    },{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1280,"quality":90,"withWebp":true,"srcSetBreakpoints":[576],"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../soluble-gatsby-source/gatsby-browser.js'),
      options: {"plugins":[],"primaryLanguage":"es","language":{"es":""},"pathsByLanguage":{"Collection":{"es":"colecciones"},"StoreTag":{"es":"tiendas-aplazame"},"Plugin":{"es":"integraciones"},"post":{"es":"{post-slug}"}},"datocms":{"localesToGenerate":["es"],"apiToken":"07df3d77e40aa3a1d0a418e25a44c2","environment":"master","modelPages":["Page","Collection","StoreTag","Plugin"]},"wordpressGraphql":{"url":"https://blog.aplazame.com/graphql","verbose":true,"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":99999999},"debug":{"graphql":{"writeQueriesToDisk":true}},"type":{"Post":{"limit":5000},"Page":{"exclude":true},"Menu":{"exclude":true},"Taxonomy":{"exclude":true},"Comment":{"exclude":true},"MenuItem":{"exclude":true},"ContentType":{"exclude":true},"Tag":{"exclude":true}},"schema":{"perPage":10},"modelPages":["Post"],"supports":{"wpml":false,"acf":false,"yoast":false,"rankmath":true}}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Aplazame","short_name":"Aplazame","start_url":"/","background_color":"#334bff","theme_color":"#334bff","display":"minimal-ui","icon":"/home/circleci/repo/aplazame-design-system/src/img/favicon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"7cf0ca66cfd279e35b143d0c570547e0"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
