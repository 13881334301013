import React from 'react';

import { Heading, Container, Row, Col } from 'soluble-design-system-base';

import textStyle from '../../scss/text.module.scss';
import style from './style.module.scss';


const Separator = () => {
    return (
        <div className={style.separator} />
    )

}

export default function TextSection({ textNode, colorScheme, ...props }) {
    colorScheme = colorScheme?.toLowerCase();
    return (
        <>
            {props.withBackgroundImageStyleA && <Separator />}
            <section className={`${props.withBackgroundImage ? style.wrapperWithImage : ""} ${props.withBackgroundImageStyleB ? style.styleImageB : ""}`}>
                {props.withBackgroundImage && props.withBackgroundImageStyleA && <img className={style.img} src={props.imageStyleA.url} />}
                {props.withBackgroundImage && props.withBackgroundImageStyleB && <img className={style.img} src={props.imageStyleB.url} />}
                <Container pt={{ xs: 10, lg: 20 }} pb={{ xs: 10, lg: 15 }}>
                    <Row>
                        <Col
                            col={{ lg: 8 }}
                            offset={{ lg: 2 }}
                            className="text-lg-center">
                            <Heading
                                tag="p"
                                className={`h2 ${style.heading} ${textStyle[`child-underline--${colorScheme}`]
                                    }`}
                                data-sal="slide-up"
                                data-sal-easing="ease-out-quart">
                                {textNode.contentWithoutWrapperUnderline}
                            </Heading>
                        </Col>
                    </Row>
                </Container>
            </section>
            {props.withBackgroundImageStyleA && <Separator />}
        </>

    );
}
