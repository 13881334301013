import React from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { gsap } from 'gsap';
import { useTranslate } from 'soluble-gatsby-source';
import { Heading } from 'aplazame-design-system';
import style from './style.module.scss';


function Calculator(props) {

    const _ = useTranslate();
    const data = useStaticQuery(graphql`
        query {
            settings: datoCmsSetting {
                calculator
                taePercentage
                ctaUrlCalculator {
                    slug
                }
            }
        }
    `);

    const json = JSON.parse(data.settings.calculator);
    const tae = data.settings.taePercentage;

    const tlRevealQuotasMobile = React.useRef();
    const wrapperRevealQuotasMobile = React.useRef();
    const wrapperCalculateQuotasMobile = React.useRef();
    const wrapperFooter = React.useRef();


    const [month, setMonth] = React.useState(1);
    const [monthFake, setMonthFake] = React.useState(3);
    const [money, setMoney] = React.useState(1);
    const [touch, setTouch] = React.useState(false);
    const [moneyFake, setMoneyFake] = React.useState(100);


    React.useEffect(() => {
        tlRevealQuotasMobile.current = gsap
            .timeline({ paused: true })
            .to(wrapperCalculateQuotasMobile.current, { autoAlpha: 0 })
            .to(wrapperFooter.current, { autoAlpha: 0 }, "<")
            .set(wrapperCalculateQuotasMobile.current, { display: 'none' })
            .set(wrapperFooter.current, { display: 'none' }, "<")
            .set(wrapperRevealQuotasMobile.current, { display: 'block' })
            .to(wrapperRevealQuotasMobile.current, {
                autoAlpha: 1,
            });

        document.querySelectorAll(".apz-input-range").forEach(function (ctrl) {
            const el = ctrl.querySelector('input');
            el.oninput = function () {
                // colorize step options
                ctrl.querySelectorAll("option").forEach(function (opt) {
                    if (opt.value <= el.valueAsNumber)
                        opt.style.backgroundColor = '#0053FF';
                    else
                        opt.style.backgroundColor = '#A7A9AC';
                });
                // colorize before and after
                var valPercent = (el.valueAsNumber - parseInt(el.min)) / (parseInt(el.max) - parseInt(el.min));
                var style = 'background-image: -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(' +
                    valPercent + ', #0053FF), color-stop(' +
                    valPercent + ', #ddd));';
                el.style = style;
            };
            el.oninput();
        });

        window.onresize = function () {
            document.querySelectorAll(".__range").forEach(function (ctrl) {
                var el = ctrl.querySelector('input');
                el.oninput();
            });
        };

        return () => {
            tlRevealQuotasMobile.current.kill();
        };
    }, []);

    return (
        <>
            <div className={style['embla__back']}>

                <div>
                    <div className={style.form}>
                        <Heading
                            tag={'h3'}
                            className={`${[style.headingSlide].join(' ')} ${style.back}`}
                        >
                            {_['calculator.heading']?.text}
                        </Heading>
                        <p dangerouslySetInnerHTML={{ __html: _['calculator.subheading']?.text }} className={style.headerText} />
                        <label>
                            <svg
                                width="34"
                                height="39"
                                viewBox="0 0 34 39"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="17" cy="19" r="17" fill="#231F20" />
                                <path
                                    d="M16.5462 25V16.136L14.7062 16.568V15L17.4102 13.8H18.6742V25H16.5462Z"
                                    fill="white"
                                />
                            </svg>
                            <span>Cuánto quieres financiar:</span>
                        </label>

                        <input type='text' readOnly value={`${moneyFake} €`}></input>


                        <div className={`apz-input-range apz-input-range--btn ${style["__range"]} ${style["__range-step"]}`}>
                            <input value={money} type="range" max="25" min="1" step="1" list="ticks1" onChange={(e) => {
                                setTouch(true);
                                switch (parseInt(e.target.value)) {
                                    case 1:
                                        setMoneyFake(100)
                                        break;
                                    case 2:
                                        setMoneyFake(200)
                                        break;
                                    case 3:
                                        setMoneyFake(300)
                                        break;
                                    case 4:
                                        setMoneyFake(400)
                                        break;
                                    case 5:
                                        setMoneyFake(500)
                                        break;
                                    case 6:
                                        setMoneyFake(600)
                                        break;
                                    case 7:
                                        setMoneyFake(700)
                                        break;
                                    case 8:
                                        setMoneyFake(800)
                                        break;
                                    case 9:
                                        setMoneyFake(900)
                                        break;
                                    case 10:
                                        setMoneyFake(1000)
                                        break;
                                    case 11:
                                        setMoneyFake(1100)
                                        break;
                                    case 12:
                                        setMoneyFake(1200)
                                        break;
                                    case 13:
                                        setMoneyFake(1300)
                                        break;
                                    case 14:
                                        setMoneyFake(1400)
                                        break;
                                    case 15:
                                        setMoneyFake(1500)
                                        break;
                                    case 16:
                                        setMoneyFake(1600)
                                        break;
                                    case 17:
                                        setMoneyFake(1700)
                                        break;
                                    case 18:
                                        setMoneyFake(1800)
                                        break;
                                    case 19:
                                        setMoneyFake(1900)
                                        break;
                                    case 20:
                                        setMoneyFake(2000)
                                        break;
                                    case 21:
                                        setMoneyFake(2100)
                                        break;
                                    case 22:
                                        setMoneyFake(2200)
                                        break;
                                    case 23:
                                        setMoneyFake(2300)
                                        break;
                                    case 24:
                                        setMoneyFake(2400)
                                        break;
                                    case 25:
                                        setMoneyFake(2500)
                                        break;

                                }
                                setMoney(e.target.value)
                            }} />
                            <datalist id="ticks1">
                                <option value="1"></option>
                                <option value="2"></option>
                                <option value="3"></option>
                                <option value="4"></option>
                                <option value="5"></option>
                                <option value="6"></option>
                                <option value="7"></option>
                                <option value="8"></option>
                                <option value="9"></option>
                                <option value="10"></option>
                                <option value="11"></option>
                                <option value="12"></option>
                                <option value="13"></option>
                                <option value="14"></option>
                                <option value="15"></option>
                                <option value="16"></option>
                                <option value="17"></option>
                                <option value="18"></option>
                                <option value="19"></option>
                                <option value="20"></option>
                                <option value="21"></option>
                                <option value="22"></option>
                                <option value="23"></option>
                                <option value="24"></option>
                                <option value="25"></option>
                            </datalist>
                        </div>

                        <div className={style.indicator}>
                            <span>100 €</span>
                            <span>2.500 €</span>
                        </div>

                        <div className={`${style.clearfix}`}></div>

                        <label className={style.separator}>
                            <svg
                                width="34"
                                height="39"
                                viewBox="0 0 34 39"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="17" cy="19" r="17" fill="#231F20" />
                                <path
                                    d="M13.2457 25V23.512C13.9284 22.9467 14.5897 22.3867 15.2297 21.832C15.8804 21.2773 16.4564 20.728 16.9577 20.184C17.4697 19.64 17.875 19.112 18.1737 18.6C18.483 18.0773 18.6377 17.5707 18.6377 17.08C18.6377 16.6213 18.5097 16.216 18.2537 15.864C18.0084 15.512 17.5977 15.336 17.0217 15.336C16.435 15.336 15.9924 15.528 15.6937 15.912C15.395 16.296 15.2457 16.76 15.2457 17.304H13.2617C13.283 16.4827 13.4644 15.8 13.8057 15.256C14.147 14.7013 14.6004 14.2907 15.1657 14.024C15.731 13.7467 16.3657 13.608 17.0697 13.608C18.211 13.608 19.0964 13.9227 19.7257 14.552C20.3657 15.1707 20.6857 15.976 20.6857 16.968C20.6857 17.5867 20.5417 18.1893 20.2537 18.776C19.9764 19.3627 19.6084 19.928 19.1497 20.472C18.691 21.016 18.195 21.528 17.6617 22.008C17.1284 22.4773 16.611 22.9147 16.1097 23.32H20.9577V25H13.2457Z"
                                    fill="white"
                                />
                            </svg>

                            <span>Durante cuánto tiempo:</span>
                        </label>

                        <input className={style.inputMonths} type='text' readOnly value={`${monthFake} meses`}></input>


                        <div className={`apz-input-range apz-input-range--btn ${style["__range"]} ${style["__range-step"]}`}>
                            <input value={month} type="range" max="6" min="1" step="1" list="ticks2" onChange={(e) => {
                                setTouch(true);
                                switch (parseInt(e.target.value)) {
                                    case 1:
                                        setMonthFake(3)
                                        break;
                                    case 2:
                                        setMonthFake(6)
                                        break;
                                    case 3:
                                        setMonthFake(9)
                                        break;
                                    case 4:
                                        setMonthFake(12)
                                        break;
                                    case 5:
                                        setMonthFake(24)
                                        break;
                                    case 6:
                                        setMonthFake(36)
                                        break;

                                }
                                setMonth(e.target.value)
                            }} />
                            <datalist id="ticks2">
                                <option value="3"></option>
                                <option value="6"></option>
                                <option value="9"></option>
                                <option value="12"></option>
                                <option value="24"></option>
                                <option value="36"></option>
                            </datalist>
                        </div>

                        <div className={style.indicator}>
                            <span>3 meses</span>
                            <span>36 meses</span>
                        </div>

                    </div>
                    <div>

                        {!touch && <>
                            <h5 className={style.noTouchHeader}>
                                Tu <br /> mensualidad:
                            </h5>
                            <img src={'/calculator2.svg'} />
                            <p>
                                Simplemente elige
                                la cantidad que quieres financiar y en cuánto tiempo.
                            </p>
                        </>}
                        {touch && <div className={style.touch}>
                            <h5>
                                Tu <br /> mensualidad:
                            </h5>
                            <div>{monthFake + 1} pagos de</div>
                            <div>{json[moneyFake][monthFake].cuotas} €</div>
                            <div className={style.summary}>
                                <span>Total</span>
                                <span>{parseFloat(moneyFake + parseFloat(json[moneyFake][monthFake].intereses.replace(',', '.'))).toFixed(2).replace('.', ',')} €</span>
                            </div>
                            <div className={`${style.summary} ${style.last}`}>
                                <span>TAE</span>
                                <span>{tae} %</span>
                            </div>
                            <div className={style.buttons}>
                                <button
                                    onClick={() => {
                                        props.close();
                                        navigate(data?.settings?.ctaUrlCalculator?.slug);

                                    }}
                                    className={`${style['button']} ${style['button--primary']}`}
                                >
                                    Ver tiendas Aplazame
                                </button>

                            </div>
                        </div>}
                    </div>

                </div>


                <span className={style.footerText}>Ejemplo de financiación con APLAZAME para una cesta de <strong>{moneyFake} €</strong> a <strong>{monthFake} meses</strong> y que comienza a pagar 30 días después de realizar la solicitud. Se solicita una entrada de <strong>{json[moneyFake][monthFake].cuotas} €</strong> que el usuario debe pagar a través de su tarjeta en el momento en que realiza la operación. El <strong>Importe a financiar</strong> es la diferencia entre el valor de la cesta y la entrada: <strong>{parseFloat(moneyFake - parseFloat(json[moneyFake][monthFake].cuotas.replace(',', '.'))).toFixed(2).replace('.', ',')}€</strong>. Se solicitan <strong>{monthFake}</strong> mensualidades de <strong>{json[moneyFake][monthFake].cuotas} €</strong>. Comisión de apaertura 0,00 €. TAE: {tae}%. <strong>Importe total adeudado {parseFloat(parseFloat(json[moneyFake][monthFake].cuotas.replace(',', '.')) * monthFake).toFixed(2).replace('.', ',')} €</strong>: Sujeto a la aprobación por parte de APLAZAME.</span>




            </div>

            <div
                className={`${style['embla__back']} ${style['embla__back--modal']}`}
            >
                <div>
                    <div className={style.form}>

                        <div ref={wrapperCalculateQuotasMobile}>
                            <Heading
                                tag={'h3'}
                                className={`${[style.headingSlide].join(' ')} ${style.back}`}
                            >
                                {_['calculator.heading']?.text}
                            </Heading>
                            <p dangerouslySetInnerHTML={{ __html: _['calculator.subheading']?.text }} className={style.headerText} />
                            <label>
                                <svg
                                    width="34"
                                    height="39"
                                    viewBox="0 0 34 39"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx="17" cy="19" r="17" fill="#231F20" />
                                    <path
                                        d="M16.5462 25V16.136L14.7062 16.568V15L17.4102 13.8H18.6742V25H16.5462Z"
                                        fill="white"
                                    />
                                </svg>
                                <span>Cuánto quieres financiar:</span>
                            </label>

                            <input type='text' readOnly value={`${moneyFake} €`}></input>


                            <div className={`apz-input-range ${style["__range"]} ${style["__range-step"]}`}>
                                <input value={money} type="range" max="25" min="1" step="1" list="ticks1" onChange={(e) => {
                                    setTouch(true);
                                    switch (parseInt(e.target.value)) {
                                        case 1:
                                            setMoneyFake(100)
                                            break;
                                        case 2:
                                            setMoneyFake(200)
                                            break;
                                        case 3:
                                            setMoneyFake(300)
                                            break;
                                        case 4:
                                            setMoneyFake(400)
                                            break;
                                        case 5:
                                            setMoneyFake(500)
                                            break;
                                        case 6:
                                            setMoneyFake(600)
                                            break;
                                        case 7:
                                            setMoneyFake(700)
                                            break;
                                        case 8:
                                            setMoneyFake(800)
                                            break;
                                        case 9:
                                            setMoneyFake(900)
                                            break;
                                        case 10:
                                            setMoneyFake(1000)
                                            break;
                                        case 11:
                                            setMoneyFake(1100)
                                            break;
                                        case 12:
                                            setMoneyFake(1200)
                                            break;
                                        case 13:
                                            setMoneyFake(1300)
                                            break;
                                        case 14:
                                            setMoneyFake(1400)
                                            break;
                                        case 15:
                                            setMoneyFake(1500)
                                            break;
                                        case 16:
                                            setMoneyFake(1600)
                                            break;
                                        case 17:
                                            setMoneyFake(1700)
                                            break;
                                        case 18:
                                            setMoneyFake(1800)
                                            break;
                                        case 19:
                                            setMoneyFake(1900)
                                            break;
                                        case 20:
                                            setMoneyFake(2000)
                                            break;
                                        case 21:
                                            setMoneyFake(2100)
                                            break;
                                        case 22:
                                            setMoneyFake(2200)
                                            break;
                                        case 23:
                                            setMoneyFake(2300)
                                            break;
                                        case 24:
                                            setMoneyFake(2400)
                                            break;
                                        case 25:
                                            setMoneyFake(2500)
                                            break;

                                    }
                                    setMoney(e.target.value)
                                }} />
                                <datalist id="ticks1">
                                    <option value="1"></option>
                                    <option value="2"></option>
                                    <option value="3"></option>
                                    <option value="4"></option>
                                    <option value="5"></option>
                                    <option value="6"></option>
                                    <option value="7"></option>
                                    <option value="8"></option>
                                    <option value="9"></option>
                                    <option value="10"></option>
                                    <option value="11"></option>
                                    <option value="12"></option>
                                    <option value="13"></option>
                                    <option value="14"></option>
                                    <option value="15"></option>
                                    <option value="16"></option>
                                    <option value="17"></option>
                                    <option value="18"></option>
                                    <option value="19"></option>
                                    <option value="20"></option>
                                    <option value="21"></option>
                                    <option value="22"></option>
                                    <option value="23"></option>
                                    <option value="24"></option>
                                    <option value="25"></option>
                                </datalist>
                            </div>

                            <div className={style.indicator}>
                                <span>100 €</span>
                                <span>2.500 €</span>
                            </div>

                            <div className={`${style.clearfix}`}></div>

                            <label className={style.separator}>
                                <svg
                                    width="34"
                                    height="39"
                                    viewBox="0 0 34 39"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <circle cx="17" cy="19" r="17" fill="#231F20" />
                                    <path
                                        d="M13.2457 25V23.512C13.9284 22.9467 14.5897 22.3867 15.2297 21.832C15.8804 21.2773 16.4564 20.728 16.9577 20.184C17.4697 19.64 17.875 19.112 18.1737 18.6C18.483 18.0773 18.6377 17.5707 18.6377 17.08C18.6377 16.6213 18.5097 16.216 18.2537 15.864C18.0084 15.512 17.5977 15.336 17.0217 15.336C16.435 15.336 15.9924 15.528 15.6937 15.912C15.395 16.296 15.2457 16.76 15.2457 17.304H13.2617C13.283 16.4827 13.4644 15.8 13.8057 15.256C14.147 14.7013 14.6004 14.2907 15.1657 14.024C15.731 13.7467 16.3657 13.608 17.0697 13.608C18.211 13.608 19.0964 13.9227 19.7257 14.552C20.3657 15.1707 20.6857 15.976 20.6857 16.968C20.6857 17.5867 20.5417 18.1893 20.2537 18.776C19.9764 19.3627 19.6084 19.928 19.1497 20.472C18.691 21.016 18.195 21.528 17.6617 22.008C17.1284 22.4773 16.611 22.9147 16.1097 23.32H20.9577V25H13.2457Z"
                                        fill="white"
                                    />
                                </svg>

                                <span>Durante cuánto tiempo:</span>
                            </label>

                            <input className={style.inputMonths} type='text' readOnly value={`${monthFake} meses`}></input>


                            <div className={`apz-input-range ${style["__range"]} ${style["__range-step"]}`}>
                                <input value={month} type="range" max="6" min="1" step="1" list="ticks1" onChange={(e) => {
                                    setTouch(true);
                                    switch (parseInt(e.target.value)) {
                                        case 1:
                                            setMonthFake(3)
                                            break;
                                        case 2:
                                            setMonthFake(6)
                                            break;
                                        case 3:
                                            setMonthFake(9)
                                            break;
                                        case 4:
                                            setMonthFake(12)
                                            break;
                                        case 5:
                                            setMonthFake(24)
                                            break;
                                        case 6:
                                            setMonthFake(36)
                                            break;

                                    }
                                    setMonth(e.target.value)
                                }} />
                                <datalist id="ticks1">
                                    <option value="3"></option>
                                    <option value="6"></option>
                                    <option value="9"></option>
                                    <option value="12"></option>
                                    <option value="24"></option>
                                    <option value="36"></option>
                                </datalist>
                            </div>

                            <div className={style.indicator}>
                                <span>3 meses</span>
                                <span>36 meses</span>
                            </div>


                            <div
                                className={style.wrapperBtnMobileCalculate}
                            >
                                <button
                                    onClick={() =>
                                        tlRevealQuotasMobile.current.play()
                                    }
                                    className={`${style['button']} ${style['button--primary']}`}
                                >
                                    Calcular
                                </button>
                            </div>
                        </div>
                        <div
                            className={style.wrapperRevealQuotasMobile}
                            ref={wrapperRevealQuotasMobile}
                        >
                            <div className={style.wrapperQuotasMobile}>
                                <h4>Tu mensualidad:</h4>
                                <h5>{monthFake + 1} pagos de</h5>
                                <div className={style.price}>{json[moneyFake][monthFake].cuotas} €</div>
                                <div className={style.summary}>
                                    <span>Total</span>
                                    <span>{parseFloat(moneyFake + parseFloat(json[moneyFake][monthFake].intereses.replace(',', '.'))).toFixed(2).replace('.', ',')} €</span>
                                </div>
                                <div className={style.summary}>
                                    <span>TAE</span>
                                    <span>{tae} %</span>
                                </div>
                                <div className={style.buttons}>
                                    <button
                                        onClick={() => {
                                            navigate(data?.settings?.ctaUrlCalculator?.slug);

                                        }}
                                        className={`${style['button']} ${style['button--primary']}`}
                                    >
                                        Ver tiendas Aplazame
                                    </button>
                                    <button
                                        onClick={() =>
                                            tlRevealQuotasMobile.current.reverse()
                                        }
                                        className={`${style['button']} ${style['button--secondary']}`}
                                    >
                                        Recalcular
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h5>
                            Tu <br /> mensualidad:
                        </h5>
                        <img src={'/calculator2.svg'} />
                        <p>
                            Simplemente escribe la cantidad que quieres
                            financiar y en cuánto tiempo.
                        </p>
                    </div>
                </div>

                <span ref={wrapperFooter} className={style.footerText}>Ejemplo de financiación con APLAZAME para una cesta de <strong>{moneyFake} €</strong> a <strong>{monthFake} meses</strong> y que comienza a pagar 30 días después de realizar la solicitud. Se solicita una entrada de <strong>{json[moneyFake][monthFake].cuotas} €</strong> que el usuario debe pagar a través de su tarjeta en el momento en que realiza la operación. El <strong>Importe a financiar</strong> es la diferencia entre el valor de la cesta y la entrada: <strong>{parseFloat(moneyFake - parseFloat(json[moneyFake][monthFake].cuotas.replace(',', '.'))).toFixed(2).replace('.', ',')} €</strong>. Se solicitan <strong>{monthFake}</strong> mensualidades de <strong>{json[moneyFake][monthFake].cuotas} €</strong>. Comisión de apaertura 0,00 €. TAE: {tae}%. <strong>Importe total adeudado {parseFloat(parseFloat(json[moneyFake][monthFake].cuotas.replace(',', '.')) * monthFake).toFixed(2).replace('.', ',')} €</strong>: Sujeto a la aprobación por parte de APLAZAME.</span>
            </div>
        </>
    )
}

export default Calculator
