import React from 'react';

import { className } from 'aplazame-design-system';
import { default as ButtonComponent } from 'aplazame-design-system/src/components/GatsbyTransitionButtonComponent';
import TransitionLink from 'aplazame-design-system/src/components/TransitionLink';

import style from './style.module.scss';

function HeaderDropdownItem({
    label,
    slug,
    description,
    type,
    openInNewWindow,
    onClick,
}) {
    return (
        <li
            className={
                slug ? style['col__item--link'] : style['col__item--title']
            }
        >
            {slug ? (
                type === 'button' ? (
                    <div
                        {...className(
                            `${style.item} ${style['button-wrapper']} d-flex align-items-center justify-content-between`
                        )}
                    >
                        {description ? (
                            <div>
                                <h4
                                    {...className(
                                        `${style['button-wrapper__description']} h4`
                                    )}
                                >
                                    {description}
                                </h4>
                            </div>
                        ) : null}
                        <div>
                            <ButtonComponent
                                button={{
                                    detached: true,
                                    externalLink: slug,
                                    block: true,
                                    label,
                                    onClick,
                                }}
                            />
                        </div>
                    </div>
                ) : (
                    <TransitionLink
                        {...className(
                            `${style.item} d-block w-100 text-decoration-none`
                        )}
                        activeClassName={style['link-active']}
                        to={slug}
                        title={label}
                        target={openInNewWindow ? '_blank' : null}
                        onClick={onClick}
                    >
                        <span {...className(`body ${style['item__name']}`)}>
                            {label}
                        </span>
                        {description ? (
                            <span
                                {...className(
                                    `overline d-block ${style['item__description']}`
                                )}
                            >
                                {description}
                            </span>
                        ) : null}
                    </TransitionLink>
                )
            ) : (
                <h4
                    {...className(
                        `${style.item} ${style['item--title']} overline`
                    )}
                >
                    {label}
                </h4>
            )}
        </li>
    );
}

export default function HeaderDropdown({ items, onClick, className: classNameCmp }) {
    const fullWidthItems = items.filter(
        (itemGroup) => itemGroup[0].column % 10 === 0
    );
    const hasFullWidthButton =
        fullWidthItems &&
            fullWidthItems.length &&
            fullWidthItems[0].length &&
            fullWidthItems[0][0].type === 'button'
            ? true
            : false;
    const otherItems = items.filter(
        (itemGroup) => itemGroup[0].column % 10 !== 0
    );

    return (
        <div
            {...className(
                `${style.wrapper} ${style[
                hasFullWidthButton
                    ? 'wrapper--with-button'
                    : 'wrapper--without-button'
                ]
                } ${style[`wrapper--for-${otherItems.length}-col`]}
                ${classNameCmp ? classNameCmp : ""}`
            )}
        >
            {fullWidthItems && fullWidthItems.length ? (
                <ul {...className('list-unstyled')}>
                    {fullWidthItems[0].map((item, i) => (
                        <HeaderDropdownItem
                            key={i}
                            {...item}
                            onClick={
                                item.onClick
                                    ? (e) => {
                                        item.onClick(e);
                                        onClick(e);
                                    }
                                    : onClick
                                        ? onClick
                                        : null
                            }
                        />
                    ))}
                </ul>
            ) : null}
            {otherItems && otherItems.length ? (
                <div {...className(`d-flex flex-column flex-lg-row`)}>
                    {otherItems.map((items, i) => (
                        <ul
                            key={i}
                            {...className(`list-unstyled ${style.cols}`)}
                        >
                            {items.map((item, ii) => (
                                <HeaderDropdownItem
                                    key={ii}
                                    {...item}
                                    onClick={
                                        item.onClick
                                            ? (e) => {
                                                item.onClick(e);
                                                onClick(e);
                                            }
                                            : onClick
                                                ? onClick
                                                : null
                                    }
                                />
                            ))}
                        </ul>
                    ))}
                </div>
            ) : null}
        </div>
    );
}
