import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { className } from 'aplazame-design-system';

import style from './style.module.scss';
import transitionStyle from 'aplazame-design-system/src/scss/transitions.module.scss';

function getSocialItems(data, props) {
    let items = [];
    let title = props.title ? props.title : '';
    let iconType = props.dark ? '' : 'Dark';

    if (props.twitterUrl) {
        items.push({
            title: `Twitter ${title}`,
            icon: data[`twitter${iconType}Icon`].publicURL,
            url: props.twitterUrl,
            cssIcon: 'aplazicon aplazicon-logo-twitter_fill',
        });
    }

    if (props.linkedinUrl) {
        items.push({
            title: `Linkedin ${title}`,
            icon: data[`linkedin${iconType}Icon`].publicURL,
            url: props.linkedinUrl,
            cssIcon: 'aplazicon aplazicon-logo-linkedin',
        });
    }

    if (props.facebookUrl) {
        items.push({
            title: `Facebook ${title}`,
            icon: data[`facebook${iconType}Icon`].publicURL,
            url: props.facebookUrl,
            cssIcon: 'aplazicon aplazicon-logo-facebook_fill',
        });
    }

    if (props.instagramUrl) {
        items.push({
            title: `Instagram ${title}`,
            icon: null,
            url: props.instagramUrl,
            cssIcon: 'aplazicon aplazicon-logo-instagram',
        });
    }

    return items;
}

export default function SocialIconsComponent({ wrapperClassName, ...props }) {
    const data = useStaticQuery(graphql`
        query {
            twitterIcon: file(relativePath: { eq: "twitter.svg" }) {
                publicURL
            }
            linkedinIcon: file(relativePath: { eq: "linkedin.svg" }) {
                publicURL
            }
            facebookIcon: file(relativePath: { eq: "facebook.svg" }) {
                publicURL
            }
            twitterDarkIcon: file(relativePath: { eq: "twitter-dark.svg" }) {
                publicURL
            }
            linkedinDarkIcon: file(relativePath: { eq: "linkedin-dark.svg" }) {
                publicURL
            }
            facebookDarkIcon: file(relativePath: { eq: "facebook-dark.svg" }) {
                publicURL
            }
        }
    `);

    return (
        <ul {...className(`list-inline ${wrapperClassName}`)}>
            {getSocialItems(data, props).map((item, i) => (
                <li
                    key={i}
                    {...className(
                        `list-inline-item ${style['social-list-item']}`
                    )}
                >
                    <a
                        href={item.url}
                        title={item.title}
                        target="_blank"
                        rel="noopener"
                        className={`${transitionStyle.link} ${item.cssIcon} ${style.cssIcon}`}
                    />
                </li>
            ))}
        </ul>
    );
}
