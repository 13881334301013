import React from 'react';
import 'aplazame-design-system/src/fonts/fonts.css';
import ModalProvider from './src/providers/ModalProvider';
import { animateScroll } from 'aplazame-design-system';
import CookieManagerProvider from './src/providers/CookieManagerProvider';

// window.page = window.page || {};
// window.page.path = window.location.pathname;

function getAnchorElement(hash = null) {
    if (!hash) {
        hash = window.location.hash
    }

    if (!hash) {
        return false
    }

    hash = hash.replace('#', '').trim()

    if (!hash) {
        return false
    }

    let element = null

    try {
        element = window.document.querySelector(`[data-anchor="${hash}"]`)
    } catch (e) {
        return null
    }

    return element ? element : null
}

function scrollIfHash(hash = null, speed = 0.25) {
    let element = getAnchorElement(hash);

    if (element) {
        let offsetTop = element.offsetTop;

        try {
            const rect = element.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            if (rect && rect.top) {
                offsetTop = rect.top + scrollTop;
            }
        } catch (e) { }

        if (!speed) {
            window.scrollTo(0, offsetTop - 80)
        } else {
            animateScroll(offsetTop, -80, speed)
        }

        const onScrollRemoveHash = () => {
            if (window.location.hash) {
                window.location.hash = '_'
            }

            window.removeEventListener('scroll', onScrollRemoveHash)
        }

        if (!element.dataset.disableReset) {
            setTimeout(() => {
                window.addEventListener('scroll', onScrollRemoveHash)
            }, 3000)
        }

        return true
    }

    return false
}

export const onPreRouteUpdate = ({ location, prevLocation, ...props }) => {
    if (prevLocation) {
        window.document.body.dataset.navigating = true
    }
}

export const onRouteUpdate = ({
    location,
    prevLocation
}) => {
    if (
        location &&
        prevLocation &&
        location.hash &&
        location.pathname === prevLocation.pathname
    ) {
        const hash = location.hash.replace('#', '').trim()
        scrollIfHash(hash)
    }

    // wrap inside a timeout to ensure the title has properly been changed
    setTimeout(() => {
        let data = window['dataLayer']
        if (data) {
            data.push({ event: `gatsby-route-change` })
        }
    }, 50)
}

export const shouldUpdateScroll = ({
    routerProps,
    prevRouterProps,
}) => {
    if (
        routerProps?.location &&
        prevRouterProps?.location &&
        routerProps.location.hash &&
        routerProps.location.pathname === prevRouterProps.location.pathname
    ) {
        const hash = routerProps.location.hash.replace('#', '').trim()
        if (
            hash === '_' ||
            hash.indexOf('/') !== -1 ||
            hash.indexOf('?') !== -1
        ) {
            return false
        }

        const hasAnchor = getAnchorElement(hash)
        return hasAnchor ? false : true
    }

    return true
}

export const wrapPageElement = ({ element, props }) => {
    return (
        <ModalProvider>
            {element}
            <CookieManagerProvider location={props.location.pathname} />
        </ModalProvider>
    )
}

export const onInitialClientRender = (a, b) => {
    scrollIfHash(null, 0)
}
