import React from 'react';

import { Tabs } from 'aplazame-design-system';

import style from './style.module.scss';


export default function TabComponent({
    items,
    className,
    linkClassName,
    activeClassName
}) {
    return (
        <Tabs
            className={`${style.tab} ${className ? className : ''}`}
            liClassName={style['tab__item']}
            linkClassName={`h4 ${style['tab__link']} d-flex align-items-center justify-content-center ${linkClassName ? linkClassName : ''}`}
            activeClassName={`${style['tab__link--active']} ${activeClassName ? activeClassName : ''}`}
            items={items}
        />
    );
}