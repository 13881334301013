import React, { useEffect, useContext, useState } from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

import { useLang } from "../../context/LangContext"
import GlobalContext from "../../context/GlobalContext"

import { SalWrapper } from "aplazame-design-system/src/utils/salWrapper";
import Header from "./Header"
import Footer from "./Footer"

const formatLinks = (array, lang, extraLinks) => {
  let items = array
    .map((group) =>
      group.edges
        .filter((edge) => edge.node.data.language === lang)
        .map(({ node }) => {
          let data = { ...node.data }
          delete data.language
          return data
        })
    )
    .filter((group) => !!group.length)

  if (extraLinks && extraLinks.length) {
    items = [...items, ...extraLinks]
  }

  return items.sort((a, b) => a[0].column - b[0].column)
}

export default function Layout({
  children,
  className = null,
  customSeo = null,
  navbarFixed = false,
  navbarTransparent = false,
  isDark = false,
  showNavbar = true,
  showFooter = true,
  footerExtraParams,
  headerExtraParams,
  structuredData,
}) {
  const [domLoaded, setDomLoaded] = useState(false);
  const { lang } = useLang()
  const { seo: contextSeo, pageUrl, headerItems } = useContext(GlobalContext)

  const [customScriptDemo, setCustomScriptDemo] = useState({
    isCheckout: false,
    isSimulator: false,
    isLoaded: false,
  })
  const simulatorScript = () => {
    return (
      <script
        src={process.env.GATSBY_APLAZAME_SCRIPT_CDN}
        defer
        async
        dangerouslySetInnerHTML={{
          __html: `
  const isBrowser = typeof window !== "undefined";
  if (!isBrowser) return
  
  const default_public_key = "${process.env.GATSBY_APLAZAME_PUBLIC_KEY}"
  const qs_params = Array.from(new URL(location).searchParams)
  .reduce((keys, [key, value]) => {
  keys[key.replace(/-/g, "_")] = value
  return keys
  }, {})
  
  ;(globalThis.aplazame ||= []).push(aplazame => {
  aplazame.init({
  public_key: qs_params.public_key || default_public_key,
  sandbox: "public_key" in qs_params ? qs_params.public_key === "true" : true,
  ...qs_params.api_base && { api_base: qs_params.api_base },
  })
  })`,
        }}
      ></script>
    )
  }

  const data = useStaticQuery(graphql`{
  site {
    siteMetadata {
      title
      description
      suffix
      siteUrl
    }
  }
  defaultSharingImage: file(
    sourceInstanceName: {eq: "img-aplazame-design-system"}
    name: {eq: "default-sharing-image"}
  ) {
    publicURL
    childImageSharp {
      resize(width: 1200, height: 630, cropFocus: CENTER) {
        src
      }
    }
  }
  header: allLink(
    filter: {data: {templateKey: {eq: "_link-header"}}}
    sort: {data: {order: ASC}}
  ) {
    group(field: {data: {column: SELECT}}) {
      edges {
        node {
          data {
            slug
            type
            order
            label
            iconClass
            description
            language
            column
            openInNewWindow
          }
        }
      }
    }
  }
  footer: allLink(
    filter: {data: {templateKey: {eq: "_link-footer"}}}
    sort: {data: {order: ASC}}
  ) {
    group(field: {data: {column: SELECT}}) {
      edges {
        node {
          data {
            slug
            type
            order
            label
            language
            column
            openInNewWindow
          }
        }
      }
    }
  }
}`)

  const seo = customSeo || contextSeo

  const defaultTitle = data.site.siteMetadata.title
  const defaultDescription = data.site.siteMetadata.description
  const url = `${data.site.siteMetadata.siteUrl}${!data.site.siteMetadata.siteUrl.endsWith("/") ? "/" : ""
    }`
  const suffix = data.site.siteMetadata.suffix

  const headerLinks = formatLinks(data.header.group, lang, headerItems)
  const footerLinks = formatLinks(data.footer.group, lang)

  const isBrowser = typeof window !== "undefined"

  if (isBrowser) {
    useEffect(() => {
      if (typeof window !== "undefined") {
        const { pathname } = window.location

        setCustomScriptDemo({
          isCheckout: pathname.includes("checkout"),
          isSimulator: pathname.includes("simulator"),
        })
      }
    }, [window.location.pathname])
  }

  useEffect(() => {
    setDomLoaded(true);
    const setViewportHeight = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }

    if (typeof window !== "undefined") {
      setViewportHeight()
      window.addEventListener("resize", setViewportHeight)
      window.addEventListener("orientationchange", setViewportHeight)
    }

    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("resize", setViewportHeight)
        window.removeEventListener("orientationchange", setViewportHeight)
      }
    }
  }, [])

  const title =
    seo && seo.title
      ? `${seo.title}${!seo.removeSuffix ? ` ${seo.titleSuffix || suffix}` : ""
      }`
      : defaultTitle
  const description =
    seo && seo.description ? seo.description.trim() : defaultDescription
  let sharingImageSrc = null

  if (seo && seo.image) {
    if (seo.image.childImageSharp && seo.image.childImageSharp.resize) {
      sharingImageSrc = seo.image.childImageSharp.resize.src
    } else if (seo.image.publicURL) {
      sharingImageSrc = seo.image.publicURL
    }
  }
  if (!sharingImageSrc && data && data.defaultSharingImage) {
    if (
      data.defaultSharingImage.childImageSharp &&
      data.defaultSharingImage.childImageSharp.resize
    ) {
      sharingImageSrc = data.defaultSharingImage.childImageSharp.resize.src
    } else if (data.defaultSharingImage.publicURL) {
      sharingImageSrc = data.defaultSharingImage.publicURL
    }
  }

  if (sharingImageSrc) {
    sharingImageSrc = `${url}${sharingImageSrc.substr(1)}`
  }

  // if (!domLoaded) {
  //   return <></>
  // }

  return (
    <>
      <Helmet>
        <html lang={lang} />
        <title>{title}</title>

        {structuredData ? (
          <script type="application/ld+json">
            {typeof structuredData === "string"
              ? structuredData
              : JSON.stringify(structuredData)}
          </script>
        ) : null}
        {pageUrl ? <link rel="canonical" href={pageUrl} /> : null}
        {seo && seo.noIndex ? <meta name="robots" content="noindex" /> : null}
        {seo &&
          seo.alternateLanguages.map((item, i) => (
            <link
              key={i}
              rel="alternate"
              hrefLang={item.language}
              href={`${url}${item.slug}/`}
            />
          ))}
        <meta name="title" content={title} />
        {description ? <meta name="description" content={description} /> : null}
        <meta property="og:type" content="website" />
        {pageUrl ? <meta property="og:url" content={pageUrl} /> : null}
        <meta property="og:title" content={title} />
        {description ? (
          <meta property="og:description" content={description} />
        ) : null}
        {sharingImageSrc ? (
          <meta property="og:image" content={sharingImageSrc} />
        ) : null}
        <meta name="twitter:card" content="summary_large_image" />
        {pageUrl ? <meta property="twitter:url" content={pageUrl} /> : null}
        <meta name="twitter:title" content={title} />
        {description ? (
          <meta name="twitter:description" content={description} />
        ) : null}
        {sharingImageSrc ? (
          <meta name="twitter:image" content={sharingImageSrc} />
        ) : null}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap"
          rel="stylesheet"
        />

        <script
          type="text/javascript"
          src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
          async
        ></script>

        {customScriptDemo.isSimulator && simulatorScript()}

        {!customScriptDemo.isSimulator && (
          <script
            id="cdn-script"
            src={process.env.GATSBY_APLAZAME_SCRIPT}
            defer
            async
          ></script>
        )}
      </Helmet>
      {showNavbar && (
        <Header
          title={defaultTitle}
          navs={headerLinks}
          isDark={isDark}
          language={lang}
          extraParams={headerExtraParams}
        />
      )}
      <main
        {...(className && { className })}
        {...(navbarFixed && {
          [navbarTransparent
            ? "data-navbar-child-height"
            : "data-navbar-height"]: true,
        })}
      >
        <SalWrapper>
          {children}
        </SalWrapper>

        {/* <HeroSectionVideo />
        <ValueProSection />
        <CarouselSection />
        <StepsSection />
        <FlipCarousel />
        <ScrollableSection />
        <BlockTextImageSection styleV2={true} />
        <FaqSection />
        <BlockTextImageSection />
        <CtaSection styleV2={true} /> */}
      </main>
      {showFooter && (
        <Footer
          title={defaultTitle}
          navs={footerLinks}
          language={lang}
          extraParams={footerExtraParams}
        />
      )}
    </>
  )
}
