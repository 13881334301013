import React, {
    useRef,
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle,
    useCallback,
} from 'react';

import { customersLogin } from '../../api';

import { Text, className } from 'aplazame-design-system';
import { useTranslate } from 'soluble-gatsby-source';
import FormInput from '../FormInput';

export default forwardRef(function FormLoginCustomers(
    { onToggleLoading, onSuccess, onError, showMaintenanceModal },
    ref
) {
    const translate = useTranslate();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const documentIdRef = useRef();
    const phoneRef = useRef();

    useEffect(() => {
        if (onToggleLoading) {
            onToggleLoading(loading);
        }
    }, [loading, onToggleLoading]);

    useEffect(() => {
        if (!error) {
            documentIdRef.current.clearError();
            phoneRef.current.clearError();
        }

        if (onError) {
            onError(error);
        }
    }, [error, onError]);

    const submitForm = useCallback(
        (e) => {
            if (e) {
                e.preventDefault();
            }

            const idIsValid = documentIdRef.current.validate();
            const phoneIsValid = phoneRef.current.validate();

            if (!idIsValid || !phoneIsValid) {
                return;
            }

            setLoading(true);

            customersLogin(
                documentIdRef.current.getValue(),
                phoneRef.current.getValue()
            )
                .then((response) => {
                    if (onSuccess) {
                        onSuccess({
                            documentId: documentIdRef.current.getValue(),
                            phone: phoneRef.current.getValue(),
                            ...response,
                        });
                    }
                })
                .catch((error) => {
                    const loginError =
                        error.non_field_errors || !Object.keys(error).length;
                    if (loginError === 'service_unavailable') {
                        showMaintenanceModal(true);
                        return;
                    }

                    if (error.document_id) {
                        if (
                            error.document_id.toLowerCase().indexOf('valid') !==
                            -1
                        ) {
                            documentIdRef.current.setError(
                                translate['modal.login.b2c.form.id.error'].text
                            );
                        } else {
                            loginError = true;
                            documentIdRef.current.setError(' ');
                        }
                    } else if (loginError) {
                        documentIdRef.current.setError(' ');
                    }

                    if (error.phone) {
                        phoneRef.current.setError(
                            translate['modal.login.b2c.form.phone.error'].text
                        );
                    } else if (loginError) {
                        phoneRef.current.setError(' ');
                    }

                    if (error.is_trottle_error) {
                        setError(translate['modal.signin.error.throttle'].text);
                    }

                    if (loginError) {
                        setError(translate['modal.login.b2c.error'].text);
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [onSuccess, translate]
    );

    const reset = useCallback(() => {
        setError(null);
        documentIdRef.current.clearError();
        documentIdRef.current.setValue('');
        phoneRef.current.clearError();
        phoneRef.current.setValue('');
    }, []);

    const onFocus = useCallback(() => {
        setError(null);
    }, []);

    useImperativeHandle(ref, () => ({
        submitForm,
        reset,
    }));

    return (
        <form onSubmit={submitForm}>
            <Text tag="div" className="body pt-5 pb-3">
                {translate['modal.login.b2c.text'].md}
            </Text>
            <FormInput
                ref={documentIdRef}
                type="text"
                name="document_id"
                required={true}
                label={translate['modal.login.b2c.form.id'].text}
                onFocus={onFocus}
                extraAtts={{
                    maxLength: 9,
                }}
            />
            <FormInput
                ref={phoneRef}
                type="tel"
                name="phone"
                required={true}
                label={translate['modal.login.b2c.form.phone'].text}
                pattern="\d*"
                onFocus={onFocus}
                extraAtts={{
                    maxLength: 9,
                }}
            />
            <button type="submit" {...className('d-none')} />
        </form>
    );
});
