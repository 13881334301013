import React from 'react';
import { motion } from "framer-motion"
import CssClassNames from '../../scss/CssClassNames';
const { className, getCssProp } = new CssClassNames({}, ['utility', 'text']);
import style from './style.module.scss';

const fadeInUp2 = {
    hidden: {
        opacity: 0,
        y: 4,
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            duration: 0.25,
            type: 'tween',
            ease: 'easeIn',
            when: 'beforeChildren',
            staggerChildren: 0.1,
        },
    },
};

export const Heading = ({
    children,
    tag,
    mt,
    mb,
    pt,
    pb,
    parentRef,
    ...props
}) => {
    const Tag = [
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'p',
        'span',
        'div',
    ].includes(tag)
        ? tag
        : 'p';

    if (props.framer) {
        return (
            <motion.div
                variants={fadeInUp2}
                initial="hidden"
                whileInView="visible"
                viewport={{ amount: 0.8, once: true }}>
                <Tag
                    {...(parentRef && { ref: parentRef })}
                    {...props}
                    {...className(
                        `${props.className ? props.className : ''} ${
                            style.heading
                        } ${tag === 'span' ? 'd-block' : ''} ${getCssProp(
                            { mt, mb, pt, pb },
                            ['mt', 'mb', 'pt', 'pb'],
                        )}`,
                    )}>
                    {children.split(' ').map((word, wordI) => (
                        <motion.span
                            key={`word-${word}-${wordI}`}
                            variants={fadeInUp2}
                            style={{
                                position: 'relative',
                                display: 'inline-block',
                                width: 'auto',
                            }}
                            dangerouslySetInnerHTML={{
                                __html: `${word}${'\u00A0'}`,
                            }}></motion.span>
                    ))}
                </Tag>
            </motion.div>
        );
    }

    const subStyle = props.className
        .split(' ')
        .filter((className) => style[className] !== undefined)
        .map((className) => style[className])
        .join(' ');

    return (
        <Tag
            {...(parentRef && { ref: parentRef })}
            {...props}
            {...className(
                `${props.className ? props.className : ''} ${
                    style.heading
                } ${subStyle} ${tag === 'span' ? 'd-block' : ''} ${getCssProp(
                    { mt, mb, pt, pb },
                    ['mt', 'mb', 'pt', 'pb'],
                )}`,
            )}
            dangerouslySetInnerHTML={{ __html: children }}
        />
    );
};
