import React, {
    useRef,
    useState,
    forwardRef,
    useImperativeHandle,
    useCallback,
    useEffect
} from 'react';

import { Row, Col } from 'aplazame-design-system';

import FormInput from '../FormInput';
import PasswordForceDecoupled from '../PasswordForceDecoupled';

import style from './style.module.scss';


export default forwardRef(function FormSignupVendorsHubspotPassword(
    { translate, onToggleAllowSubmit },
    ref
) {
    const [passwordError, setPasswordError] = useState(false);
    const [password, setPassword] = useState('');
    const [allowSubmit, setAllowSubmit] = useState(false);

    const passwordRef = useRef();
    const password2Ref = useRef();
    const passwordForceRef = useRef();

    const validate = useCallback(
        (e) => {
            if (e) {
                e.preventDefault();
            }
            
            const passwordIsValid = passwordRef.current.validate();
            const password2IsValid = password2Ref.current.validate();
            const passwordValue = passwordRef.current.getValue();
            const password2Value = password2Ref.current.getValue();

            if (
                !passwordIsValid ||
                !password2IsValid ||
                passwordValue !== password2Value
            ) {
                if (passwordValue !== password2Value) {
                    setPasswordError(true);
                    passwordRef.current.setError(' ');
                    password2Ref.current.setError(
                        translate['modal.password-change.form.password2.error']
                            .text
                    );
                }

                return false;
            }
            
            return true;
        },
        [translate, setPasswordError]
    );

    const getValue = useCallback(
        () => {
            return validate() ? passwordRef.current.getValue() : null;
        },
        [ validate, passwordRef ]
    )

    const validateSubmit = useCallback(
        (password1 = null, password2 = null) => {
            if (password1 === null) {
                password1 = passwordRef.current.getValue();
            }
            if (password2 === null) {
                password2 = password2Ref.current.getValue();
            }

            const isPasswordOk = passwordForceRef.current.validate();
            setAllowSubmit(isPasswordOk && password2 && password2.length);
        },
        [setAllowSubmit]
    );

    const onChangePassword = useCallback(
        (e) => {
            setPassword(e.target.value);
            validateSubmit(e.target.value);
        },
        [validateSubmit, setPassword]
    );

    const onChangePassword2 = useCallback(
        (e) => {
            validateSubmit(null, e.target.value);
        },
        [validateSubmit]
    );

    const onFocusPassword = useCallback(() => {
        if (passwordError) {
            passwordRef.current.setValue('');
            password2Ref.current.setValue('');
            setPasswordError(false);
            setAllowSubmit(false);
            setPassword('');
        } else {
            passwordRef.current.clearError();
            password2Ref.current.clearError();
        }
    }, [passwordError, setPasswordError, setAllowSubmit, setPassword]);

    useEffect(
        () => {
            if (onToggleAllowSubmit) {
                onToggleAllowSubmit(allowSubmit);
            }
        },
        [ allowSubmit, onToggleAllowSubmit ]
    );

    useImperativeHandle(ref, () => ({
        validate,
        getValue
    }));

    return (
        <Row className={style.row}>
            <Col col={{ lg: 6 }}>
                <FormInput
                    ref={passwordRef}
                    translate={translate}
                    type="password"
                    name="password"
                    required={true}
                    label={translate['modal.signup.b2b.password'].text}
                    onFocus={onFocusPassword}
                    onChange={onChangePassword}
                    className={style.input}
                />
            </Col>
            <Col col={{ lg: 6 }}>
                <FormInput
                    ref={password2Ref}
                    translate={translate}
                    type="password"
                    name="password2"
                    required={true}
                    label={translate['modal.signup.b2b.password2'].text}
                    onFocus={onFocusPassword}
                    onChange={onChangePassword2}
                    className={style.input}
                />
            </Col>
            <Col>
                <PasswordForceDecoupled
                    translate={translate}
                    ref={passwordForceRef}
                    password={password}
                    className="mb-3"
                />
            </Col>
        </Row>
    );
});
