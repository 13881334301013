import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { vendorsChangePassword } from '../../api';

import { className, ModalBase } from 'aplazame-design-system';
import { useTranslate } from 'soluble-gatsby-source';
import FormInput from '../FormInput';
import FormSubmit from '../FormSubmit';
import ModalChangePasswordSuccess from '../ModalChangePasswordSuccess';
import PasswordForce from '../PasswordForce';


export default function ModalChangePassword({
    uid
}) {
    const translate = useTranslate();

    const modalRef = useRef();
    const passwordForceRef = useRef();
    const newPassword1Ref = useRef();
    const newPassword2Ref = useRef();

    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ password, setPassword ] = useState('');
    const [ success, setSuccess ] = useState(false);

    const [ allowSubmit, setAllowSubmit ] = useState(false);

    const data = useStaticQuery(graphql`
        query {
            settings: datoCmsSetting {
                loginB2bHash
            }
        }
    `);
    
    useEffect(() => {
        if (modalRef.current) {
            modalRef.current.toggle();
        }
    }, []);

    const submitForm = useCallback(
        (e) => {
            if (e) {
                e.preventDefault();
            }
    
            if (!allowSubmit) {
                return;
            }

            const password1 = newPassword1Ref.current.getValue();
            const password2 = newPassword2Ref.current.getValue();

            if (password1 !== password2) {
                newPassword1Ref.current.setError(' ');
                newPassword2Ref.current.setError(translate['modal.password-change.form.password2.error'].text);
                return;
            }
    
            setLoading(true);
    
            vendorsChangePassword(
                uid,
                newPassword1Ref.current.getValue(),
                newPassword2Ref.current.getValue()
            ).then(response => {
                setSuccess(true);
            })
            .catch(error => {
                setError(translate['modal.password-change.error'].text);
            })
            .finally(() => {
                setLoading(false);
            });
        },
        [ allowSubmit, translate, uid ]
    );

    const onFocus = useCallback(
        () => {
            if (error) {
                newPassword1Ref.current.setValue('');
                newPassword2Ref.current.setValue('');
                setError(null);
            } else {
                newPassword1Ref.current.clearError();
                newPassword2Ref.current.clearError();
            }
        },
        [ error ]
    );

    const validateSubmit = useCallback(
        (password1 = null, password2 = null) => {
            if (password1 === null) {
                password1 = newPassword1Ref.current.getValue();
            }
            if (password2 === null) {
                password2 = newPassword2Ref.current.getValue();
            }

            const isPassword1Ok = passwordForceRef.current.validate();
            setAllowSubmit(isPassword1Ok && password2 && password2.length);
        },
        []
    );

    const onChangePassword1 = useCallback(
        e => {
            setPassword(e.target.value);
            validateSubmit(e.target.value);
        },
        [ validateSubmit ]
    );

    const onChangePassword2 = useCallback(
        e => {
            validateSubmit(null, e.target.value);
        },
        [ validateSubmit ]
    );

    const onToggle = useCallback(
        show => {
            if (typeof window !== 'undefined' && !show) {
                window.location.hash = data.settings.loginB2bHash;
            }
        },
        [ data.settings.loginB2bHash ]
    );

    const onToggleSuccess = useCallback(
        show => {
            if (!show) {
                modalRef.current.toggle();
            }
        },
        []
    );
    
    return (
        <>
            <ModalBase
                parentRef={modalRef}
                onToggle={onToggle}
                title={translate['modal.password-change.title'].text}
                footer={(
                    <FormSubmit 
                        text={translate['modal.password-change.submit'].text}
                        onClick={submitForm}
                        loading={loading}
                        allowSubmit={allowSubmit}
                        error={error}
                    />
                )}
            >
                <form 
                    onSubmit={submitForm}
                    {...className('mt-lg-3')}
                >
                    <FormInput
                        ref={newPassword1Ref}
                        type="password"
                        name="password1"
                        required={true}
                        label={translate['modal.password-change.form.password1'].text}
                        onFocus={onFocus}
                        onChange={onChangePassword1}
                    />
                    <FormInput
                        ref={newPassword2Ref}
                        type="password"
                        name="password2"
                        required={true}
                        label={translate['modal.password-change.form.password2'].text}
                        onFocus={onFocus}
                        onChange={onChangePassword2}
                    />
                </form>
                <PasswordForce 
                    ref={passwordForceRef}
                    password={password}
                />
            </ModalBase>
            {success ? (
                <ModalChangePasswordSuccess 
                    onToggle={onToggleSuccess}
                />
            ) : null}
        </>
    );
}