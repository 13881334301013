import React from 'react';

export let Section = ({ name, title, children }) => (
    <section>
        <a name={name} href={`#${name}`}>{title ? title : name}</a>
        {children}
    </section>
);

export let SectionSpy = ({ render }) => render({});

if (typeof window !== 'undefined') {
    const SmartSections = require('react-smart-sections');
    SectionSpy = SmartSections.SectionSpy;
    Section = SmartSections.Section;
}