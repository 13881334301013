import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Collapse } from 'react-collapse';

import CssClassNames from '../../scss/CssClassNames';
import moduledStyles from './styles.module.scss';
const { className } = new CssClassNames(moduledStyles, ['utility']);

export const Accordion = forwardRef(
    (
        {
            items = [],
            initialShow = undefined,
            wrapperClassName = '',
            itemClassName = '',
            openItemClassName = '',
            titleClassName = '',
            bodyClassName = '',
            allShown = false,
            lock = false,
            light = false,
            onOpenItemChange = () => { },
        },
        ref,
    ) => {
        const [show, setShow] = useState(initialShow);
        useImperativeHandle(ref, () => ({
            openItem: (index) => {
                if (index < items.length) {
                    setShow(index);
                    onOpenItemChange(index);
                }
            },
            closeAll: () => {
                if (show || show === 0) {
                    setShow(null);
                    onOpenItemChange(null);
                }
            },
        }));

        return (
            <div {...className(wrapperClassName)} id="accordion">
                {items.map((item, i) => (
                    <div
                        key={i}
                        {...className(
                            `${itemClassName} ${i === show || allShown
                                ? `${openItemClassName} open`
                                : ''
                            }`,
                        )}>
                        <button
                            {...className(
                                `w-100 d-block position-relative title ${titleClassName} ${light == true ? 'light' : ''
                                }`,
                            )}
                            type="button"
                            data-toggle="collapse"
                            data-target={`#collapse${i}`}
                            aria-expanded={
                                i === show || allShown ? 'true' : 'false'
                            }
                            aria-controls={`#collapse${i}`}
                            id={`heading${i}`}
                            onClick={(e) => {
                                e.preventDefault();
                                if (allShown || lock) {
                                    return;
                                }

                                setShow(show === i ? null : i);
                                onOpenItemChange(show === i ? null : i);
                            }}>
                            <span className={moduledStyles.spanQuestion} dangerouslySetInnerHTML={{ __html: item.title }}>
                            </span>
                        </button>
                        <Collapse
                            isOpened={i === show || allShown}
                            id={`collapse${i}`}
                            aria-labelledby={`heading${i}`}
                            data-parent="#accordion"
                            theme={{
                                collapse: className('collapse').className,
                                content: bodyClassName,
                            }}>
                            {item.body}
                        </Collapse>
                    </div>
                ))}
            </div>
        );
    },
);
