import React from 'react';

import { className } from 'soluble-design-system-base';

import style from './style.module.scss';


export default function Spinner({
    forButton = false,
    size,
    color = 'light',
    borderSmall = true
}) {
    return forButton ? (
        <>
            <span 
                {...className(`${style['spinner-border']} ${style['spinner-border-sm']} text-light`)}
                role="status"
                aria-hidden="true"
            />
            <span {...className('sr-only')}>Loading...</span>
        </>
    ) : (
        <div 
            {...className(`${style['spinner-border']} ${borderSmall ? style['spinner-border-sm'] : ''} text-${color}`)}
            style={size ? { width: size, height: size } : {}}
            role="status"
        >
            <span {...className('sr-only')}>Loading...</span>
        </div>
    )
};