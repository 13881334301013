export default function webpIsSupported(callback) {
    if(!window.createImageBitmap){
        callback(false);
        return;
    }

    var webpdata = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';

    fetch(webpdata).then(function(response){
        return response.blob();
    }).then(function(blob){
        createImageBitmap(blob).then(function(){
            callback(true);
        }, function(){
            callback(false);
        });
    });
}