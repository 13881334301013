import React, { useRef, useEffect } from 'react';

import { Text, Button, ModalBase } from 'aplazame-design-system';
import { useTranslate } from 'soluble-gatsby-source';


export default function ModalChangePasswordSuccess({
    onToggle
}) {
    const translate = useTranslate();

    const modalRef = useRef();
    
    useEffect(() => {
        modalRef.current.toggle();
    }, []);
    
    return (
        <ModalBase
            parentRef={modalRef}
            onToggle={onToggle}
            title={translate['modal.password-change.success.title'].text}
            size="md"
            success={true}
            footer={(
                <Button 
                    type="primary"
                    block={true}
                    Tag="button"
                    onClick={modalRef && modalRef.current ? modalRef.current.toggle : null}
                >
                    {translate['modal.password-change.success.accept'].text}
                </Button>
            )}
        >
            <Text tag="div" className="body text-center pb-5 pb-lg-11">
                {translate['modal.password-change.success.text'].md}
            </Text>
        </ModalBase>
    );
}