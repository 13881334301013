import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from "gatsby";

import { getUrlParam } from '../utils';

import ModalLogin from '../components/ModalLogin';
import ModalRecoverPassword from '../components/ModalRecoverPassword';
import ModalChangePassword from '../components/ModalChangePassword';
import ModalSignup from '../components/ModalSignup';
import ModalSignupActivation from '../components/ModalSignupActivation';
import ModalVideo from '../components/ModalVideo';
import ModalCalculator from '../components/ModalCalculator';


export default function ModalProvider({ children }) {
    const data = useStaticQuery(graphql`
        query  {
            hashes: datoCmsSetting {
                loginB2bRememberPasswordHash
                loginHash
                loginB2cHash
                loginB2bHash
                signupB2bHash
            }
        }
    `);

    const [modalLogin, setModalLogin] = useState(null);
    const [modalRecoverPassword, setModalRecoverPassword] = useState(null);
    const [modalChangePassword, setModalChangePassword] = useState(null);
    const [modalSignup, setModalSignup] = useState(null);
    const [modalSignupActivation, setModalSignupActivation] = useState(null);
    const [modalVideo, setModalVideo] = useState(null);
    const [modalCalculator, setModalCalculator] = useState(null);

    useEffect(() => {
        if (!data.hashes) {
            return;
        }

        let hashes = Object.values(data.hashes);
        let hashKeys = Object.keys(data.hashes);

        function onHashChangeListener() {
            const hash = window.location.hash.replace('#', '');

            if (hash) {
                if (hash === '_') {
                    setTimeout(() => {
                        setModalLogin(null);
                        setModalSignup(null);
                        setModalRecoverPassword(null);
                        setModalChangePassword(null);
                        setModalVideo(null);
                        setModalCalculator(null);
                    }, 400);
                } else if (hash.indexOf('?uid=') > -1) {
                    const match = window.location.hash.match(/(.*)\?uid=(.*)/);
                    if (match.length === 3 && match[2]) {
                        setModalChangePassword(match[2]);
                    }

                    setTimeout(() => {
                        setModalLogin(null);
                        setModalRecoverPassword(null);
                    }, 400);
                } else if (hash.indexOf('?v=') > -1) {
                    const match = window.location.hash.match(/(.*)\?v=(.*)/);
                    if (match.length === 3 && match[2]) {
                        setModalVideo(match[2]);
                    }
                } else if (hash === data.hashes.signupB2bHash) {
                    setModalLogin(null);
                    setModalRecoverPassword(null);
                    setModalChangePassword(null);
                    setModalVideo(null);
                    setModalCalculator(null);
                    setModalSignup(true);
                }
                else if (hash === "calculator") {
                    setModalLogin(null);
                    setModalRecoverPassword(null);
                    setModalChangePassword(null);
                    setModalVideo(null);
                    setModalSignup(null);
                    setModalCalculator(true);
                }
                else {
                    const nr = hashes.indexOf(hash);
                    if (nr > -1) {
                        setModalVideo(null);
                        setModalSignup(null);
                        setModalLogin(hashKeys[nr]);

                        if (hashKeys[nr] === 'loginB2bRememberPasswordHash') {
                            setModalRecoverPassword(true);
                        } else {
                            setTimeout(() => {
                                setModalRecoverPassword(null);
                            }, 400);
                        }
                    }
                }
            }
        }

        function checkSignupActivation() {
            const uuid = getUrlParam('uuid');
            const token = getUrlParam('token');

            if (uuid && token) {
                setModalSignupActivation({
                    uuid,
                    token
                });
            }
        }

        window.addEventListener('hashchange', onHashChangeListener);
        onHashChangeListener();
        checkSignupActivation();

        return () => {
            window.removeEventListener('hashchange', onHashChangeListener);
        }
    }, [
        data,
        setModalLogin,
        setModalRecoverPassword,
        setModalChangePassword,
        setModalSignup,
        setModalSignupActivation,
        setModalVideo,
        setModalCalculator
    ]);

    const onClose = () => {
        if (typeof window !== 'undefined') {
            window.document.body.removeAttribute('data-modal-open');
        }
        setModalCalculator(null);
    };

    return (
        <>
            {children}
            {modalLogin ? (
                <ModalLogin
                    activeTab={['loginB2bHash', 'loginB2bRememberPasswordHash'].includes(modalLogin) ? 1 : 0}
                />
            ) : null}
            {modalSignup ? (
                <ModalSignup />
            ) : null}
            {modalRecoverPassword ? (
                <ModalRecoverPassword />
            ) : null}
            {modalChangePassword ? (
                <ModalChangePassword
                    uid={modalChangePassword}
                />
            ) : null}
            {modalSignupActivation ? (
                <ModalSignupActivation
                    {...modalSignupActivation}
                />
            ) : null}
            {modalVideo ? (
                <ModalVideo
                    videoId={modalVideo}
                />
            ) : null}
            {modalCalculator ? (
                <ModalCalculator close={onClose} />
            ) : null}
        </>
    )
}
