import React, { useRef, useEffect, useState, useCallback } from 'react';

import { customersPhoneCode } from '../../api';

import { Text, className, ModalBase } from 'aplazame-design-system';
import { useTranslate } from 'soluble-gatsby-source';

import FormInput from '../FormInput';
import FormSubmit from '../FormSubmit';
import FormAlert from '../FormAlert';


export default function ModalPhoneCode({
    documentId = null,
    phone = null,
    code = null,
    onToggle = null
}) {
    const translate = useTranslate();
    const modalRef = useRef();
    const codeRef = useRef();

    const [ allowSubmit, setAllowSubmit ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(null);
    
    useEffect(() => {
        if (phone && modalRef.current) {
            modalRef.current.toggle();
        }
    }, [ phone ]);

    useEffect(() => {
        if (code && codeRef.current) {
            codeRef.current.setValue(code);
            setAllowSubmit(true);
        }
    }, [ code ]);

    const submitForm = useCallback(
        (e) => {
            if (e) {
                e.preventDefault();
            }

            if (!codeRef.current.validate()) {
                return;
            }

            setLoading(true);

            customersPhoneCode(codeRef.current.getValue(), phone, documentId)
                .catch((error) => {
                    if (error.is_trottle_error) {
                        setError({ isTrottleError: true })
                    }
                    codeRef.current.setError(translate['modal.phonecode.error'].text);
                    setLoading(false);
                });
        },
        [ translate ]
    );

    const onChange = useCallback(
        e => {
            setAllowSubmit(!!e.target.value);
        },
        []
    );
    
    return (
        <ModalBase
            parentRef={modalRef}
            title={translate['modal.phonecode.title'].text}
            size="md"
            onToggle={onToggle}
            footer={(
                <FormSubmit 
                    text={translate['modal.phonecode.submit'].text}
                    onClick={submitForm}
                    loading={loading}
                    allowSubmit={allowSubmit}
                />
            )}
        >
            <Text tag="div" className="body text-center pb-5 pt-lg-3 pl-lg-4 pr-lg-4">
                {translate['modal.phonecode.text'].md.replace('{phone}', phone)}
            </Text>
            <form 
                onSubmit={submitForm}
                {...className('mb-lg-10')}
            >
                <FormInput 
                    ref={codeRef}
                    type="text"
                    name="code"
                    required={true}
                    value={code}
                    label={translate['modal.phonecode.form.code'].text}
                    pattern="\d*"
                    onChange={onChange}
                />
            </form>
            {error && error.isTrottleError &&
            <FormAlert>
                {translate['modal.signin.error.throttle'].text}
            </FormAlert>}
        </ModalBase>
    );
}