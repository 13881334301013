import React, { useRef, useEffect, useCallback } from 'react';

import { className, ModalBase } from 'aplazame-design-system';
import { useTranslate } from 'soluble-gatsby-source';

import FormSubmit from '../FormSubmit';

export default function ModalMaintenance({
    onToggle,
}) {
    const translate = useTranslate();
    const modalRef = useRef();
    
    useEffect(() => {
        modalRef.current.toggle();
    }, []);

    const closeModal = useCallback(
        () => {
            onToggle(false)
        }, [])

    return (
        <ModalBase
            parentRef={modalRef}
            title={translate['modal.maintenance.title'].text}
            size="sm"
            onToggle={onToggle}
            footer={
                <FormSubmit 
                    text={translate['modal.maintenance.back'].text}
                    onClick={closeModal}/>
            }>
            <div>
                <p className={className(`mb-3`).className}>{translate['modal.maintenance.under_maintenance'].text}</p>
                <p className={className(`mb-3`).className}>{translate['modal.maintenance.will_be_back_soon'].text}</p>
                <p>{translate['modal.maintenance.thank_you'].text}</p>
            </div>
        </ModalBase>
    );
}