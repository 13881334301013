import React, { useState, useContext, createContext, useMemo } from 'react';
import { useStaticQuery, graphql } from "gatsby";

const LangContext = createContext(null);

const LangProvider = ({ children, options = {} }) => {

    const dataTranslate = useStaticQuery(graphql`
        query {
            allDatoCmsTranslation {
                edges {
                    node {
                        key
                        md: text(locale: "es")
                        text: textNode(locale: "es") {
                            contentWithoutWrapper
                        }
                    }
                }
            }
        }
    `);

    const primaryLanguage = options.primaryLanguage;
    const translateInitial = options.dictionaryConstants || {};

    dataTranslate.allDatoCmsTranslation.edges.reduce((obj, edge) => {
        obj[edge.node.key] = {
            ["es"]: {
                text: edge.node.text.contentWithoutWrapper,
                md: edge.node.md
            },
            ["en"]: {
                text: "",
                md: ""
            }
        }
        return obj;
    }, translateInitial);

    const [state, setState] = useState({
        lang: primaryLanguage,
        translate: translateInitial
    });

    const value = useMemo(() => {
        return { state, setState }
    }, [state]);

    return <LangContext.Provider value={value}>{children}</LangContext.Provider>
}

// Hooks
function useLang() {
    const { state, setState } = useContext(LangContext);

    function setLang(lang) {
        setState(state => ({
            ...state,
            lang
        }));
    }

    return {
        lang: state.lang,
        setLang
    }
}

function useTranslate() {
    const { state } = useContext(LangContext);

    return Object.keys(state.translate).reduce((newObj, key) => {
        newObj[key] = state.translate[key][state.lang];
        return newObj;
    }, {});
}

export {
    LangProvider,
    useLang,
    useTranslate
}