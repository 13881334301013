import React, { useState, useEffect, useRef, useCallback } from 'react';

import gtagInjector from 'aplazame-design-system/src/utils/gtagInjector';

export default function CookieManagerProvider(props) {
    useEffect(() => {
        gtagInjector(process.env.GATSBY_GTAG_URL);
    }, []);

    return <></>;
}
