import React, { useRef, useEffect, useCallback } from 'react';
import { useTranslate } from 'soluble-gatsby-source';
import { ModalBase } from 'aplazame-design-system';
import style from './style.module.scss';
import Calculator from '../../../../aplazame-web/src/components/Calculator';

export default function ModalCalculator(props) {

    const translate = useTranslate();
    const modalRef = useRef();

    useEffect(() => {
        if (modalRef.current) {
            modalRef.current.toggle();
        }
    }, []);

    const onToggle = useCallback(
        show => {
            if (typeof window !== 'undefined' && !show) {
                window.location.hash = '_';
            }
        },
        []
    );




    return (
        <>
            <ModalBase
                parentRef={modalRef}
                onToggle={onToggle}
                wrapperClassName={style.wrapperModalClassName}
                bodyClassName={style.bodyModalClassName}
                titleClassName={style.titleModalClassName}
                headerClassName={style.headerModalClassName}
                title={"Calcula tu mensualidad"}
            >
                <Calculator close={props.close} />
            </ModalBase>

        </>
    );
}
