import React, { useRef, useEffect, useState } from 'react';

import { className } from 'soluble-design-system-base';

import style from './style.module.scss';
import transitionStyle from '../../scss/transitions.module.scss';

export default function HeaderAlert({
    children,
    onClose,
    wrapperClass,
    fixed = true,
}) {
    const [height, setHeight] = useState(null);
    const wrapperRef = useRef();

    useEffect(() => {
        if (
            typeof window === 'undefined' ||
            !wrapperRef ||
            !wrapperRef.current ||
            !fixed
        ) {
            return;
        }

        const setThisHeight = () => {
            setHeight(wrapperRef.current?.offsetHeight);
        };

        setTimeout(setThisHeight, 300);
        window.addEventListener('resize', setThisHeight);

        return () => {
            window.removeEventListener('resize', setThisHeight);
        };
    }, [wrapperRef, setHeight, fixed]);

    useEffect(() => {
        if (
            typeof window === 'undefined' ||
            height === null ||
            !fixed ||
            !wrapperRef.current
        ) {
            return;
        }

        const header = window.document.querySelector('header');
        if (header && header.firstChild) {
            header.firstChild.style.transform = `translateY(${height}px)`;
        }

        const main = window.document.querySelector('main');
        if (main) {
            main.style.transform = `translateY(${height}px)`;
            main.style.marginBottom = `${height}px`;
        }

        if (!height) {
            wrapperRef.current.dataset.hide = 'true';
        }

        if (height === 0 && onClose) {
            setTimeout(onClose, 300);
        }
    }, [height, onClose, fixed, wrapperRef]);

    return (
        <div
            id="banner"
            ref={wrapperRef}
            {...className(
                `${fixed ? 'fixed-top' : ''
                } bg-primary h4 text-lg-center pt-2 pb-2 pl-3 pr-9 pl-lg-9 ${style.wrapper
                } ${wrapperClass}`,
            )}>
            <div
                {...className(
                    `${style.content} ${transitionStyle['child-link-underline-dark']}`,
                )}
                dangerouslySetInnerHTML={{ __html: children }}
            />
            <button
                {...className(`${style.close}`)}
                onClick={() => {
                    setHeight(0);
                }}>
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
                        fill="#fff"></path>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M13.7023 7.03463C13.9057 6.83123 13.9057 6.50145 13.7023 6.29806C13.4989 6.09466 13.1691 6.09466 12.9657 6.29806L10.0007 9.26311L7.0356 6.29806C6.8322 6.09466 6.50243 6.09466 6.29903 6.29806C6.09564 6.50145 6.09564 6.83123 6.29903 7.03463L9.26408 9.99967L6.29903 12.9647C6.09563 13.1681 6.09563 13.4979 6.29903 13.7013C6.50243 13.9047 6.8322 13.9047 7.0356 13.7013L10.0007 10.7362L12.9657 13.7013C13.1691 13.9047 13.4989 13.9047 13.7023 13.7013C13.9057 13.4979 13.9057 13.1681 13.7023 12.9647L10.7372 9.99967L13.7023 7.03463Z"
                        fill="#0053ff"></path>
                </svg>
            </button>
        </div>
    );
}
