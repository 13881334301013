import React, { useState, useEffect, useRef, useCallback } from 'react';
import { usePrevious } from '../../utils';

import style from './style.module.scss';
import transitionStyle from 'aplazame-design-system/src/scss/transitions.module.scss';

let globalId = 0;

// Hubspot requiring jQuery onFormSubmit fallback
if (typeof window !== 'undefined') {
    window.jQuery =
        window.jQuery ||
        function (nodeOrSelector) {
            return {
                serializeArray: () => {},
            };
        };
}

function loadHubspotScript(onload) {
    let script = window.document.createElement(`script`);

    script.defer = true;
    if (onload) {
        script.onload = onload;
    }
    script.src = `//js.hsforms.net/forms/v2.js`;

    window.document.head.appendChild(script);
}

export default function HubspotForm({
    loading,
    forceLoadingStatus,
    onReady,
    onSubmit,
    onFocusField,
    submitDisabled,
    submitBlock,
    blockedDomains,
    className,
    gtmEventName,
    ...props
}) {
    const elementRef = useRef();
    const [loaded, setLoaded] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [id, setId] = useState(null);
    const prevId = usePrevious(id);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        globalId++;
        setId(`reactHubspotForm${globalId}`);
    }, [setId]);

    const setupSubmitDisabled = useCallback(
        (submitDisabled) => {
            if (!elementRef.current) {
                return;
            }

            let input = elementRef.current.querySelector(
                'input[type="submit"]'
            );
            if (input) {
                input.disabled = submitDisabled;
            }
        },
        [elementRef]
    );

    const onFormReady = useCallback(() => {
        if (!elementRef.current) {
            return;
        }

        const inputs = elementRef.current.querySelectorAll(
            'input[type="text"],input[type="email"],input[type="tel"],input[type="number"],input[type="date"],textarea'
        );

        const onInit = (element) => {
            if (element.value.trim()) {
                element.parentNode.parentNode.dataset.filled = 'true';
                element.parentNode.parentNode.parentNode.dataset.filled =
                    'true';
            }
        };

        const onFocus = (e) => {
            if (onFocusField) {
                onFocusField();
            }

            if (e.target.dataset.error) {
                delete e.target.dataset.error;
                const label = e.target.parentNode.parentNode.querySelector(
                    '[data-error-message]'
                );
                if (label) {
                    label.remove();
                }
            }

            e.target.parentNode.parentNode.dataset.filled = 'true';
            e.target.parentNode.parentNode.parentNode.dataset.filled = 'true';
        };

        const onBlur = (e) => {
            if (!e.target.value.trim()) {
                e.target.parentNode.parentNode.dataset.filled = '';
                e.target.parentNode.parentNode.parentNode.dataset.filled = '';
            }
        };

        [...inputs].forEach((input) => {
            input.addEventListener('focus', onFocus);
            input.addEventListener('blur', onBlur);
            onInit(input);
        });

        const onInitSelect = (element) => {
            if (element.selectedIndex) {
                element.parentNode.parentNode.dataset.filled = 'true';
                element.parentNode.parentNode.parentNode.dataset.filled =
                    'true';
                element.dataset.selected = 'true';
            }
        };

        const onBlurSelect = (e) => {
            if (!e.target.selectedIndex) {
                e.target.parentNode.parentNode.dataset.filled = '';
            }
        };

        const onChangeSelect = (e) => {
            e.target.dataset.selected = e.target.selectedIndex ? 'true' : '';
        };

        const selects = elementRef.current.querySelectorAll('select');
        [...selects].forEach((select) => {
            select.addEventListener('focus', onFocus);
            select.addEventListener('blur', onBlurSelect);
            select.addEventListener('change', onChangeSelect);
            onInitSelect(select);
        });

        if (onFocusField) {
            const radios = elementRef.current.querySelectorAll(
                'input[type="radio"],input[type="checkbox"]'
            );
            const onChangeRadio = (e) => {
                onFocusField();
            };

            [...radios].forEach((radio) => {
                radio.addEventListener('change', onChangeRadio);
            });
        }

        setupSubmitDisabled(submitDisabled);
    }, [elementRef, submitDisabled, onFocusField]);

    useEffect(() => {
        if (id && !prevId) {
            let createFormInterval = null;

            function createForm() {
                if (!window.hbspt || !elementRef.current) {
                    return;
                }

                let options = {
                    ...props,
                    submitButtonClass: style.submit,
                    errorClass: `${style['with-error']} hubspot-field-with-error`,
                    errorMessageClass: style['error-message'],
                    target: `#${id}`,
                    blockedDomains: blockedDomains ? blockedDomains : [],
                    onFormSubmit: ($form) => {
                        setLoadingStatus(true);
                        let data = window['dataLayer'];
                        if (data && gtmEventName) {
                            const emailHb = document.getElementsByName('email')[0];
                            data.push({ email: emailHb?.value || "",  event: gtmEventName });
                            data.push({ event: gtmEventName });
                        }
                        var formData = $form.serializeArray();
                        if (onSubmit) {
                            onSubmit(formData);
                        }
                    },
                    onFormReady: () => {
                        setLoaded(true);
                        onFormReady();
                        if (onReady) {
                            onReady(elementRef.current);
                        }
                    },
                };

                window.hbspt.forms.create(options);
                window.clearInterval(createFormInterval);
            }

            function startCreateForm() {
                if (createFormInterval) {
                    return;
                }

                createFormInterval = setInterval(createForm, 300);
            }

            if (!window.hbspt) {
                loadHubspotScript(startCreateForm);
            } else {
                startCreateForm();
            }
        }
    }, [
        id,
        prevId,
        elementRef,
        props,
        onSubmit,
        onReady,
        setLoaded,
        setLoadingStatus,
        blockedDomains,
    ]);

    useEffect(() => {
        setupSubmitDisabled(submitDisabled);
    }, [submitDisabled]);

    return id ? (
        <>
            <div
                className={[
                    style.form,
                    transitionStyle['child-link-underline'],
                    forceLoadingStatus || loadingStatus
                        ? style['form--loading']
                        : '',
                    submitDisabled ? style['form--submit-disabled'] : '',
                    submitBlock ? style['form--submit-block'] : '',
                    className ? className : '',
                ].join(' ')}
                ref={elementRef}
                id={id}
                style={{ display: loaded ? 'block' : 'none' }}
            />
            {!loaded && loading ? loading : null}
        </>
    ) : loading ? (
        loading
    ) : null;
}
