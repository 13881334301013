import React, { useRef, useEffect, useState, useCallback } from 'react';

import { useTranslate } from 'soluble-gatsby-source';
import { ModalBase } from 'aplazame-design-system'; 

import ModalMaintenance from '../ModalMaintenance';
import FormSignupVendorsHubspot from '../FormSignupVendorsHubspot';
import ModalSignupSuccess from '../ModalSignupSuccess';


export default function ModalSignup() {
    const translate = useTranslate();

    const modalRef = useRef();
    const formSignupRef = useRef();

    const [ error, setError ] = useState(null);
    const [ success, setSuccess ] = useState(null);
    const [ qaUrl, setQaUrl ] = useState(null);
    
    const [ maintenance, setMaintenance ] = useState(null);

    useEffect(() => {
        if (window.isMaintenanceMode) {
            setMaintenance(true);
        } else {
            if (modalRef.current) {
                modalRef.current.toggle();
            }
        }
    }, []);

    const onToggle = useCallback(
        show => {
            if (typeof window !== 'undefined' && !show) {
                window.location.hash = '_';
            }
        },
        []
    );

    const onToggleSuccess = useCallback(
        show => {
            if (!show) {
                modalRef.current.toggle();
            }
        },
        [ modalRef ]
    );

    const onFormSuccess = useCallback(
        (successResponse, url) => {
            setSuccess(successResponse);
            if (url) {
                setQaUrl(url);
            }
        },
        [ setSuccess, setQaUrl ]
    );    

   const toggleMaintenanceModal = useCallback(
        (show) => {
            setMaintenance(show);
            if (!show) {
                if (typeof window !== 'undefined') {
                    window.location.hash = '_';
                }
            }
        },
        []
    );

    const onError = useCallback(
        (error) => {
            if (error === 'service_unavailable') {
                toggleMaintenanceModal(true)
            }
            setError(error)
        },
        []
    );

    return (
        <>
            <ModalBase
                parentRef={modalRef}
                onToggle={onToggle}
                title={translate['modal.signup.b2b.title'].text}
                titleDetached={true}
            >
                <FormSignupVendorsHubspot
                    ref={formSignupRef}
                    onError={onError}
                    onSuccess={onFormSuccess}
                />
            </ModalBase>
            {success ? (
                <ModalSignupSuccess
                    onToggle={onToggleSuccess}
                    text={success}
                    qaUrl={qaUrl}
                />
            ) : null}

            { maintenance ? 
                <ModalMaintenance 
                    onToggle={toggleMaintenanceModal} /> 
                : null }
        </>
    );
}