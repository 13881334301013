import React from 'react';

import { Modal, Text } from 'soluble-design-system-base';
import { SuccessIcon, ErrorIcon, WarningIcon } from '../Icon';

import style from './style.module.scss';
import textStyle from '../../scss/text.module.scss';

const ModalBaseTitle = ({ title = '', detached, className }) => (
    <Text
        className={
            className
                ? className
                : `text-center d-block w-100 font-weight-bold ${
                      detached
                          ? `h2 ${textStyle.gradual}`
                          : `h3 ${style['modal__title']}`
                  }`
        }>
        {title}
    </Text>
);

export default function ModalBase({
    title,
    titleClassName,
    titleDetached,
    children,
    parentRef = null,
    onToggle,
    footer = null,
    size = null,
    wrapperClassName,
    bodyClassName,
    headerClassName,
    mainClassName,
    closeButtonClassName,
    success,
    error,
    warning,
    hideClose,
    disableBackgroundClose,
    iconTimeout = 500,
}) {
    return (
        <Modal
            ref={parentRef}
            onToggle={onToggle}
            header={
                title || success || error || warning || !hideClose ? (
                    <>
                        {success ? (
                            <SuccessIcon
                                timeout={iconTimeout}
                                className="w-100 mt-3 mb-lg-5"
                            />
                        ) : null}
                        {error ? (
                            <ErrorIcon
                                timeout={iconTimeout}
                                className="w-100 mt-3 mb-lg-5"
                            />
                        ) : null}
                        {warning ? (
                            <WarningIcon
                                timeout={iconTimeout}
                                className="w-100 mt-3 mb-lg-5"
                            />
                        ) : null}
                        {title ? (
                            <ModalBaseTitle
                                title={title}
                                className={`${titleClassName} ${style.modalTitle}`}
                                detached={titleDetached}
                            />
                        ) : null}
                    </>
                ) : null
            }
            footer={footer}
            disableBackgroundClose={disableBackgroundClose}
            closeButtonClassName={`${style['modal__close']} ${
                hideClose ? 'd-none' : ''
            } ${closeButtonClassName ? closeButtonClassName : ''}`}
            wrapperClassName={wrapperClassName ? wrapperClassName : ''}
            dialogClassName={`${style.modal} ${
                mainClassName ? mainClassName : ''
            }`}
            bodyClassName={`${style['modal__body']} ${
                bodyClassName ? bodyClassName : ''
            }`}
            headerClassName={`${style['modal__header']} ${
                title && (success || error || warning) ? 'flex-column' : ''
            } ${headerClassName ? headerClassName : ''}`}
            footerClassName={style['modal__footer']}
            size={size}>
            {children}
        </Modal>
    );
}
