import React, { useState, useEffect } from 'react';
import { navigate, Link } from 'gatsby';
import { useTransitionState } from 'gatsby-plugin-transition-link/hooks';


export default function NavbarBrandLink(props) {
    const state = useTransitionState();
    const [navigate, setNavigate] = useState(null);
    const { transitionStatus, mount } = state;

    useEffect(() => {
        if (navigate && transitionStatus === 'entered' && mount === true) {
            navigate(navigate);
            setNavigate(null);
        }
    }, [
        transitionStatus,
        mount,
        navigate,
        setNavigate
    ]);

    return (
        <Link
            {...props}
            onClick={e => {
                if (transitionStatus !== 'entered' || mount !== true) {
                    e.preventDefault();
                    setNavigate(props.to);
                }
            }}
        />
    );
}
