import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { vendorsRecoverPassword } from '../../api';

import { Text, ModalBase } from 'aplazame-design-system';
import { useTranslate } from 'soluble-gatsby-source';
import ModalMaintenance from '../ModalMaintenance';
import FormInput from '../FormInput';
import FormSubmit from '../FormSubmit';

import style from './style.module.scss';


export default function ModalRecoverPassword() {
    const translate = useTranslate();
    const modalRef = useRef();
    const emailRef = useRef();

    const [ allowSubmit, setAllowSubmit ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ email, setEmail ] = useState(null);
    const [ maintenance, setMaintenance ] = useState(null);
    
    const data = useStaticQuery(graphql`
        query {
            settings: datoCmsSetting {
                loginB2bHash
            }
        }
    `);

    const onToggle = useCallback(
        show => {
            if (typeof window !== 'undefined') {
                if (!show) {
                    window.location.hash = data.settings.loginB2bHash;
                } else {
                    setEmail(null);
                }
            }
        },
        [ data.settings.loginB2bHash ]
    );
    
    useEffect(() => {
        if (modalRef.current) {
            modalRef.current.toggle();
        }
    }, []);

    const submitForm = useCallback(
        (e) => {
            if (e) {
                e.preventDefault();
            }

            if (email) {
                return modalRef.current.toggle();
            }

            if (!emailRef.current.validate()) {
                return;
            }

            setLoading(true);

            vendorsRecoverPassword(emailRef.current.getValue())
                .then(() => {
                    setEmail(emailRef.current.getValue());
                }).catch(error => {
                    const loginError = error.non_field_errors || !Object.keys(error).length;
                    if (loginError === 'service_unavailable') {
                        showMaintenanceModal(true)
                    }
                }).finally(() => {
                    setLoading(false);
                });
        },
        [ email ]
    );

    const onChange = useCallback(
        e => {
            setAllowSubmit(!!e.target.value);
        },
        []
    );

    const showMaintenanceModal = useCallback(
        (show) => {
            setMaintenance(show);
        },
        []
    );
    
    return (
        <>
        <ModalBase
            parentRef={modalRef}
            onToggle={onToggle}
            title={email ?
                translate['modal.password-recover.success.title'].md.replace('{email}', email.length > 20 ? `<span class="${style['large-email']}">${email}</span>` : email) : 
                translate['modal.password-recover.title'].text
            }
            size="md"
            bodyClassName={style.body}
            footer={(
                <FormSubmit
                    text={email ?
                        translate['modal.password-recover.success.accept'].text :
                        translate['modal.password-recover.submit'].text
                    }
                    onClick={submitForm}
                    loading={loading}
                    allowSubmit={allowSubmit}
                    isSubmit={email ? false : true}
                />
            )}
        >
            <Text tag="div" className="body text-center pb-5 pt-lg-3 pl-lg-4 pr-lg-4">
                {email ? 
                    translate['modal.password-recover.success.text'].md :
                    translate['modal.password-recover.text'].md
                }
            </Text>
            {!email ? (
                <form 
                    onSubmit={submitForm}
                >
                    <FormInput
                        ref={emailRef}
                        type="email"
                        name="email"
                        required={true}
                        label={translate['modal.password-recover.form.email'].text}
                        onChange={onChange}
                    />
                </form>
            ) : null}
        </ModalBase>
        { maintenance ? 
            <ModalMaintenance 
                onToggle={showMaintenanceModal} /> 
            : null }
    </>);
}