import React, { useRef, useEffect, useCallback } from 'react';

import { ModalBase } from 'aplazame-design-system';

import style from './style.module.scss';


export default function ModalVideo({
    videoId
}) {
    const modalRef = useRef();
    
    useEffect(() => {
        if (modalRef.current) {
            modalRef.current.toggle();
        }
    }, []);

    const onToggle = useCallback(
        show => {
            if (typeof window !== 'undefined' && !show) {
                window.location.hash = '_';
            }
        },
        []
    );
    
    return (
        <ModalBase
            parentRef={modalRef}
            onToggle={onToggle}
            size="lg"
            headerClassName={style['modal-header']}
            bodyClassName={style['modal-body']}
            mainClassName={style.main}
            closeButtonClassName={style['modal-close']}
        >
            <iframe
                width="100%"
                height="358"
                src={`https://www.youtube.com/embed/${videoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </ModalBase>
    );
}